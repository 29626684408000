var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-alert', {
    staticClass: "mb-0",
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "XCircleIcon"
    }
  }), _vm._v(" Ubah data "), _c('strong', [_vm._v("terkunci")]), _vm._v(" karena ijin bunker telah "), _c('strong', [_vm._v("Expired")]), _vm._v(" dan "), _c('strong', [_vm._v("Belum Sandar")]), _vm._v(". ")], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }