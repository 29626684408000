var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.uploading ? _c('b-spinner', {
    attrs: {
      "small": "",
      "variant": "primary"
    }
  }) : _vm._e(), _vm._v(" " + _vm._s(_vm.uploading ? 'Uploading....' : '') + " "), !_vm.mutateIsHaveFile.length ? _c('b-row', [_c('b-col', {
    staticClass: "mb-1",
    attrs: {
      "md": "7"
    }
  }, [_c('v-dropzone', {
    ref: "files",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "id": "files",
      "options": {
        url: _vm.uri,
        method: "post",
        maxFilesize: 3,
        maxFiles: _vm.maxFiles,
        chunking: true,
        acceptedFiles: _vm.acceptFile,
        autoProcessQueue: false,
        thumbnailWidth: 200,
        addRemoveLinks: true,
        addDownloadLinks: true,
        dictDefaultMessage: "".concat(_vm.info),
        dictRemoveFile: 'Hapus'
      },
      "add-remove-links": ""
    },
    on: {
      "vdropzone-removed-file": _vm.removeFile,
      "vdropzone-file-added": _vm.addFile,
      "vdropzone-complete": _vm.afterComplete
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('app-timeline', {
    staticClass: "mb-1"
  }, _vm._l(_vm.files, function (item, index) {
    return _c('app-timeline-item', {
      key: index
    }, [_c('div', {
      staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
    }), _c('p', [_c('b-img', {
      staticClass: "mr-1",
      attrs: {
        "src": require('@/assets/images/icons/xls.png'),
        "height": "auto",
        "width": "20"
      }
    }), _c('span', {
      staticClass: "align-bottom cursor-pointer"
    }, [_vm._v(_vm._s(item.name))])], 1), _c('small', {
      staticClass: "text-muted"
    }, [_vm._v("Size " + _vm._s(_vm.sizeUpload(item.size)))])]);
  }), 1), _vm.updateID && _vm.$store.state.app.roleName !== 'viewer' ? _c('b-button', {
    attrs: {
      "variant": "primary",
      "disabled": _vm.$can('manage', 'special')
    },
    on: {
      "click": function click($event) {
        return _vm.uploadFile();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "FileTextIcon"
    }
  }), _vm._v(" Upload Pengajuan RKBM ")], 1) : _vm._e()], 1)], 1) : _c('b-row', {
    staticClass: "match-height"
  }, [_c('b-col', {
    attrs: {
      "md": "7"
    }
  }, [_vm.$store.state.app.roleName !== 'viewer' && _vm.$store.state.app.roleName !== 'jetty man' && _vm.$store.state.app.roleName !== 'security' ? _c('b-button', {
    staticClass: "mb-1",
    attrs: {
      "variant": "warning",
      "block": "",
      "disabled": _vm.$can('manage', 'special')
    },
    on: {
      "click": function click($event) {
        return _vm.reUpload();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "FileTextIcon"
    }
  }), _vm._v(" " + _vm._s(_vm.reUploadTitle) + " ")], 1) : _vm._e()], 1), _c('b-col', {
    attrs: {
      "md": "4"
    }
  }, [_c('app-timeline', _vm._l(_vm.mutateIsHaveFile, function (item, index) {
    return _c('app-timeline-item', {
      key: index
    }, [_c('div', {
      staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
    }), _c('p', [_c('b-img', {
      staticClass: "mr-1",
      attrs: {
        "src": require('@/assets/images/icons/xls.png'),
        "height": "auto",
        "width": "20"
      }
    }), _c('span', {
      staticClass: "align-bottom"
    }, [_c('a', {
      staticStyle: {
        "color": "grey"
      },
      attrs: {
        "href": item.download_link,
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_vm._v(_vm._s(item.original_name))])])], 1), _c('small', {
      staticClass: "text-muted"
    }, [_vm._v("Size " + _vm._s(item.size) + " "), _c('strong', [_vm._v("[ click name file, to preview.]")])])]);
  }), 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }