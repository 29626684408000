<template>
  <div>
    <b-spinner v-if="uploading" small variant="primary" />
    {{ uploading ? 'Uploading....' : '' }}
    <b-row v-if="!mutateIsHaveFile.length">
      <b-col md="7" class="mb-1">
        <v-dropzone
          id="files"
          ref="files"
          :style="{ cursor: 'pointer' }"
          :options="{
            url: uri,
            method: `post`,
            maxFilesize: 3,
            maxFiles: maxFiles,
            chunking: true,
            acceptedFiles: acceptFile,
            autoProcessQueue: false,
            thumbnailWidth: 200,
            addRemoveLinks: true,
            addDownloadLinks: true,
            dictDefaultMessage: `${info}`,
            dictRemoveFile: 'Hapus'
          }"
          add-remove-links
          @vdropzone-removed-file="removeFile"
          @vdropzone-file-added="addFile"
          @vdropzone-complete="afterComplete"
        >
          <!-- duplicate-check -->
        </v-dropzone>
      </b-col>

      <b-col md="4">
        <app-timeline class="mb-1">
          <app-timeline-item v-for="(item, index) in files" :key="index">
            <div
              class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
            ></div>
            <p>
              <b-img
                :src="require('@/assets/images/icons/xls.png')"
                height="auto"
                width="20"
                class="mr-1"
              />
              <span class="align-bottom cursor-pointer">{{ item.name }}</span>
            </p>
            <small class="text-muted">Size {{ sizeUpload(item.size) }}</small>
          </app-timeline-item>
        </app-timeline>
        <b-button
          v-if="updateID && $store.state.app.roleName !== 'viewer'"
          variant="primary"
          @click="uploadFile()"
          :disabled="$can('manage', 'special')"
        >
          <feather-icon
            icon="FileTextIcon"
            :style="{ cursor: 'pointer' }"
            class="mr-50"
          />
          Upload Pengajuan RKBM
        </b-button>
      </b-col>
    </b-row>
    <b-row v-else class="match-height">
      <b-col md="7">
        <b-button
          variant="warning"
          block
          @click="reUpload()"
          class="mb-1"
          v-if="
            $store.state.app.roleName !== 'viewer' &&
            $store.state.app.roleName !== 'jetty man' &&
            $store.state.app.roleName !== 'security'
          "
          :disabled="$can('manage', 'special')"
        >
          <feather-icon
            icon="FileTextIcon"
            :style="{ cursor: 'pointer' }"
            class="mr-50"
          />
          {{ reUploadTitle }}
        </b-button>
      </b-col>
      <b-col md="4">
        <app-timeline>
          <app-timeline-item
            v-for="(item, index) in mutateIsHaveFile"
            :key="index"
          >
            <div
              class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
            ></div>
            <p>
              <b-img
                :src="require('@/assets/images/icons/xls.png')"
                height="auto"
                width="20"
                class="mr-1"
              />
              <span class="align-bottom">
                <a
                  :href="item.download_link"
                  target="_blank"
                  style="color: grey"
                  rel="noopener noreferrer"
                  >{{ item.original_name }}</a
                >
              </span>
            </p>
            <small class="text-muted"
              >Size {{ item.size }}
              <strong>[ click name file, to preview.]</strong></small
            >
          </app-timeline-item>
        </app-timeline>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import VDropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import useJwt from '@/auth/jwt/useJwt'

export default {
  components: {
    VDropzone,
    AppTimeline,
    AppTimelineItem
  },
  data() {
    return {
      files: [],
      uploading: false,
      mutateIsHaveFile: this.isHaveFile,
      stateDuplicate: false
    }
  },
  props: {
    info: {
      type: String,
      required: true
    },
    reUploadTitle: {
      type: String,
      required: true
    },
    uri: {
      type: String,
      required: true
    },
    isHaveFile: {
      type: [Object, Array],
      required: true
    },
    acceptFile: {
      type: String,
      required: true
    },
    maxFiles: {
      type: Number,
      required: true
    },
    updateID: {
      type: Number,
      required: false
    },
    modelName: {
      type: String,
      required: false
    }
  },
  computed: {},
  methods: {
    sizeUpload(bytes, decimals = 2) {
      if (!+bytes) return '0 Bytes'

      const k = 1024
      const dm = decimals < 0 ? 0 : decimals
      const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']

      const i = Math.floor(Math.log(bytes) / Math.log(k))

      return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
    },
    reUpload() {
      if (
        this.$store.state.app.roleName === 'viewer' ||
        this.$can('manage', 'special')
      ) {
        this.$swal({
          position: 'top-end',
          icon: 'warning',
          title: 'You dont have permission',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
        return
      }
      this.files.forEach((file) => {
        this.$refs.files.removeFile(file)
      })
      this.files = []
      this.mutateIsHaveFile = []
      console.log('upload #re_upload :: ', this.files)
      console.log('upload #re_upload :: ', this.mutateIsHaveFile)
    },
    uploadFile() {
      if (this.$can('manage', 'special')) {
        return
      } else {
        this.upload()
      }
    },
    dropzoneTemplate() {
      return `
        <div class="dz-preview dz-file-preview relative">
          <div class="dz-image">
            <img data-dz-thumbnail>
          </div>
          <div class="dz-details">
            <div class="dz-filename">
              <a class="" href="javascript:undefined;">
                <h5 class="text-white cursor-pointer" data-dz-name></h5>
                </a>
              <div class="dz-size mt-1" data-dz-size></div>
            </div>
          </div>
          <div class="dz-error-message">
            <span data-dz-errormessage></span>
          </div>
          <div class="dz-success-mark">
          </div>
          <a class="dz-remove mr-1" href="javascript:undefined;" data-dz-remove>Hapus</a>

        </div>
      `
    },
    addFile(file) {
      console.log('addFile::', file)
      if (file.status !== 'error') {
        this.files.push(file)
      }
      console.log('addFile:: #files ', this.files)
      if (!this.updateID) {
        console.log('later creates')
        this.$emit('callBack', this.files)
      }
    },
    removeFile(removeFile) {
      console.log('removeFile', removeFile)
      this.files = this.files.filter(
        (file) => file.upload.uuid != removeFile.upload.uuid
      )
      this.mutateIsHaveFile = this.mutateIsHaveFile.filter(
        (file) => file.upload.uuid != removeFile.upload.uuid
      )
      this.$emit('callBack', this.files)
    },
    afterComplete(file) {
      console.log('completed::', file.status)
      if (file.status === 'error') {
        this.$alert({
          title: `Max 3 Files`,
          text: `tidak dapat menambah file lebih dari tiga`,
          variant: 'danger',
          icon: 'XCircleIcon'
        })
        this.files = this.files.filter((file) => file.status !== 'error')
        console.log('completed::', this.files)
      }
    },
    upload() {
      this.uploading = true
      const formData = new FormData()
      const model_id = this.updateID || null
      const model = this.modelName
      const uriAction = `file_storage/${model_id}/${model}`

      console.log('upload :: ', this.files)

      this.files.forEach((file) => {
        if (file instanceof File) {
          formData.append('file', file)
        } else {
          console.log(`form data else : ${file}`)
        }
      })
      useJwt.http
        .put(uriAction, formData)
        .then((response) => {
          this.reUpload()
          const { file_storages } = response.data
          file_storages.forEach((item) => {
            this.mutateIsHaveFile.push({
              original_name: `${item.original_name}`,
              size: `${item.size}`,
              download_link: `${this.uri}${item.path}/${item.unique_name}`
            })
          })
          console.log('file_storages:: ', this.mutateIsHaveFile)
          this.$alert({
            title: response.data.message,
            variant: 'success',
            icon: 'CheckIcon'
          })
          this.$emit('callBack', 'success')
          this.uploading = false
        })
        .catch((err) => {
          this.uploading = false
          console.log(`error ${err}`)
          this.reUpload()
        })
    }
  }
}
</script>
