<template>
  <div id="form_masuk">
    <b-progress
      v-if="fetchLoading"
      key="info"
      animated
      value="100"
      variant="info"
      :class="'progress-bar-info'"
    />

    <b-row align-h="between" align-v="center">
      <b-col cols="4">
        <strong>Ubah Status </strong>

        <b-dropdown
          v-if="$router.currentRoute.params.id"
          id="status"
          :variant="disableActionButton() ? 'danger' : formStatusVariant(form.schedule.form_status)"
        >
          <template #button-content>
            <span v-if="disableActionButton()" v-text="'IJIN BUNKER EXPIRED'"></span>
            <span v-else v-text="form.schedule.form_status || 'Tidak ada status'" />
          </template>
          <b-dropdown-header
            v-if="form.schedule.form_status !== 'KEGIATAN DIBATALKAN'"
            id="dropdown-header-label"
          >
            <small class="text-secondary">Ubah status ke </small>
          </b-dropdown-header>
          <b-dropdown-item
            v-if="
              !disableActionButton() &&
              form.schedule.form_status !== 'KEGIATAN DIBATALKAN' &&
              $store.state.app.roleName !== 'viewer'
            "
            variant="danger"
            @click="onUpdateStatus(true, form.schedule.form_status)"
          >
            <b-badge :variant="'danger'"> BATALKAN KEGIATAN </b-badge>
          </b-dropdown-item>
          <b-dropdown-item v-else variant="info">
            <b-badge
              :variant="'info'"
              v-if="$store.state.app.roleName !== 'viewer'"
            >
              <span v-if="disableActionButton()">
                Ijin Bunker Telah Expired
              </span>
              <span v-else>
                Tidak Dapat Mengubah Status yang Telah Dibatalkan.
              </span>
            </b-badge>
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
      <b-col
        v-if="$router.currentRoute.params.id !== undefined"
        class="text-center d-none d-sm-block"
        cols="4"
      >
        <h1>TUG BOAT</h1>
        <hr />
        <b-badge variant="light-primary">{{
          formName
        }}</b-badge>
      </b-col>
      <b-col class="text-right" cols="4">
        <div class="float-right">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="outline-secondary"
            :style="{ cursor: 'pointer' }"
            :to="$router.currentRoute.params.previous_link"
          >
            <feather-icon icon="ArrowLeftIcon" :style="{ cursor: 'pointer' }" />
            <span>Kembali</span>
          </b-button>
        </div>
      </b-col>
      <b-col
        v-if="$router.currentRoute.params.id !== undefined"
        class="text-center d-block d-sm-none"
        cols="12"
      >
        <hr />
        <h1>CREATED BY</h1>
        <hr />
      </b-col>
    </b-row>

    <div class="mt-1">
      <form-wizard
        key="wizardKey"
        ref="wizardFirst"
        color="#7367F0"
        :title="null"
        :subtitle="null"
        :lazy="true"
        layout="vertical"
        :before-change="() => true"
        finish-button-text="Terbitkan Form Perencanaan Kedaatangan Kapal"
        back-button-text="Previous"
        :class="
          isLastStep()
            ? dataID
              ? 'vertical-steps steps-transparent mb-3 sal-hide'
              : 'vertical-steps steps-transparent mb-3 sal'
            : 'vertical-steps steps-transparent mb-3 sal'
        "
        @on-complete="formSubmitted"
        @on-change="onTabChange"
      >
        <!-- TUG BOAT -->
        <tab-content
          :title="wizard.first.step2.title"
          :icon="wizard.first.step2.icon"
        >
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">{{ wizard.first.step2.content.title }}</h5>
              <small class="text-muted">
                <strong>{{ wizard.first.step2.content.subtitle }}.</strong>
              </small>
            </b-col>
          </b-row>
          <!-- START OF TUG BOAT -->
          <validation-observer ref="tbbmRulesTugBoat" tag="form">
            <b-row>
              <b-col md="12" cols="12">
                <b-row>
                  <b-col md="12" cols="12">
                    <b-form-group
                      label="Name Tug Boat *"
                      label-for="vi-name_tug_boat"
                    >
                      <div class="suggest-custom">
                        <validation-provider
                          #default="{ errors }"
                          name="Name Tug Boat"
                          :rules="{
                            required: true
                          }"
                        >
                          <vue-autosuggest
                            id="vi-tug_boat-name"
                            v-model="form.tug_boat.name_tug_boat"
                            v-uppercase
                            :suggestions="[{ data: handlingTugBoat() }]"
                            :get-suggestion-value="getSuggestionValue"
                            :input-props="{
                              id: 'autosuggest__input',
                              class: 'form-control',
                              placeholder: 'Boat Name',
                              disabled: permissionSuggestionTugBoatMain()
                            }"
                            @focus="
                              getSuggestionValueFocus(
                                $event,
                                'tug_boat',
                                'name_tug_boat'
                              )
                            "
                            @input="
                              searchForm({
                                keyword: $event,
                                uri: 'vw_tug_boat/',
                                model: 'tug_boat',
                                currentSuggest: 'name_tug_boat'
                              })
                            "
                          >
                            <template slot-scope="{ suggestion }">
                              <div class="d-flex align-items-center">
                                <b-avatar
                                  :text="
                                    Array.from(
                                      suggestion.item.name_tug_boat
                                    )[0] +
                                    Array.from(suggestion.item.name_tug_boat)[1]
                                  "
                                  variant="primary"
                                />
                                <div class="detail ml-50">
                                  <b-card-text class="mb-0">
                                    <strong>
                                      <span
                                        v-text="
                                          `${suggestion.item.name_tug_boat}, ${suggestion.item.no_pkk_tug_boat}`
                                        "
                                      />
                                      <!-- dalam perencanaan -->
                                      <div
                                        v-if="
                                          suggestion.item.tug_status ===
                                            'Dalam Perencanaan' &&
                                          suggestion.item.model_status ===
                                            'Tersedia'
                                        "
                                      >
                                        <b-badge
                                          v-if="
                                            suggestion.item.tug_status ===
                                            'Dalam Perencanaan'
                                          "
                                          :variant="
                                            suggestion.item.model_status ===
                                            'Dalam Perencanaan'
                                              ? 'danger'
                                              : 'warning'
                                          "
                                        >
                                          <span
                                            v-text="`Sedang Direncanakan`"
                                          ></span>
                                        </b-badge>
                                      </div>
                                      <!-- sedang digunakan -->
                                      <div v-else>
                                        <b-badge
                                          v-if="
                                            suggestion.item.model_status ===
                                            'Sedang Digunakan'
                                          "
                                          :variant="
                                            suggestion.item.model_status ===
                                            'Sedang Digunakan'
                                              ? 'danger'
                                              : 'secondary'
                                          "
                                        >
                                          <span
                                            v-text="
                                              suggestion.item.model_status
                                            "
                                          ></span>
                                        </b-badge>
                                        <span
                                          v-text="
                                            `${
                                              !suggestion.item.sedang_digunakan
                                                ? ''
                                                : ` : ${suggestion.item.sedang_digunakan}`
                                            }`
                                          "
                                        ></span>
                                      </div>
                                      <small class="text-muted">
                                        <strong
                                          ><span
                                            v-text="
                                              `Updated AT ${formatDateTime(
                                                suggestion.item.updated_at
                                              )}`
                                            "
                                        /></strong>
                                      </small>
                                    </strong>
                                  </b-card-text>
                                </div>
                              </div>
                            </template>
                            <template slot="before-suggestions">
                              <div class="ml-1 mt-1 mb-1 mr-1">
                                <b-badge
                                  href="#"
                                  pill
                                  :variant="
                                    statusSuggestionColor[tug_boatState]
                                  "
                                  class="mr-1"
                                >
                                  <span
                                    >
                                    {{ statusSuggestionChoose[tug_boatState] }}
                                  </span>
                                </b-badge>
                                <b-badge variant="info">
                                  Total:
                                  {{ form.tug_boat.tug_boats.length }}
                                </b-badge>
                              </div>
                            </template>
                          </vue-autosuggest>

                          <small class="text-danger">{{ errors[0] }}</small>
                          <small
                            v-if="
                              errorPath &&
                              errorMatchValue === form.tug_boat.name_tug_boat
                            "
                            class="text-danger"
                            >{{
                              errorPath === 'name_tug_boat' ? errorValue : ''
                            }}</small
                          >
                        </validation-provider>
                        <b-spinner
                          v-if="tug_boatStateLoading"
                          class="suggest-loading"
                          small
                          variant="primary"
                        />
                      </div>
                    </b-form-group>
                  </b-col>

                  <b-col md="6" cols="6">
                    <b-form-group
                      label="GT ( Kapal ) *"
                      label-for="vi-tug_boat-gt_tug_boat"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name=" GT ( Kapal ) *"
                        rules="required"
                      >
                        <b-input-group append="">
                          <cleave
                            id="vi-tug_boat-gt_tug_boat"
                            v-model="form.tug_boat.gt_tug_boat"
                            class="form-control"
                            :raw="true"
                            :options="options.number"
                            :readonly="permissionSuggestionTugBoat()"
                            placeholder="1.000 (dot automaticaly)"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" cols="6">
                    <b-form-group
                      label="LOA ( Kapal ) *"
                      label-for="vi-tug_boat-loa_tug_boat"
                    >
                      <!-- regex: /^[0-9]+$/ -->
                      <validation-provider
                        #default="{ errors }"
                        name=" LOA ( Kapal ) *"
                        :rules="{
                          required: true
                        }"
                      >
                        <b-input-group append="M">
                          <!-- <cleave
                            id="vi-tug_boat-loa_tug_boat"
                            v-model="form.tug_boat.loa_tug_boat"
                            class="form-control"
                            :raw="true"
                            :options="options.number"
                            :readonly="permissionSuggestionTugBoat()"
                            placeholder="78,12 (comma manual)"
                          /> -->
                          <b-form-input
                            id="vi-tug_boat-loa_tug_boat"
                            v-model="form.tug_boat.loa_tug_boat"
                            class="form-control"
                            type="number"
                            placeholder="78,12 (comma manual)"
                            :readonly="permissionSuggestionTugBoat()"
                          />
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col md="6" cols="12">
                    <b-form-group
                      label="Capt. Name *"
                      label-for="vi-tug_boat-captain_name_tug_boat"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name=" Capt. Name *"
                        rules="required"
                      >
                        <b-form-input
                          id="vi-tug_boat-captain_name_tug_boat"
                          v-model="form.tug_boat.captain_name_tug_boat"
                          v-uppercase
                          :state="errors.length > 0 ? false : null"
                          type="text"
                          placeholder="Full Capt. Name"
                          :readonly="permissionSuggestionTugBoat()"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" cols="12">
                    <b-form-group
                      label="Capt. Telp *"
                      label-for="vi-tug_boat-captain_telp_tug_boat"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name=" Capt. Telp *"
                        rules="required"
                      >
                        <b-form-input
                          id="vi-tug_boat-captain_telp_tug_boat"
                          v-model="form.tug_boat.captain_telp_tug_boat"
                          :state="errors.length > 0 ? false : null"
                          type="number"
                          placeholder="081145778613"
                          :readonly="permissionSuggestionTugBoat()"
                        />

                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <b-col
                    md="6"
                    cols="12"
                    v-if="form.tug_boat.user?.abilities[0]?.action != 'TBBM JETTY 3A'"
                  >
                    <b-form-group label="Nomor PKK *" label-for="vi-no_pkk">
                      <!-- regex: /^[0-9]+$/ -->
                      <validation-provider
                        #default="{ errors }"
                        name=" Nomor PKK *"
                        :rules="{
                          required: true
                        }"
                      >
                        <b-form-input
                          id="vi-no_pkk"
                          v-model="form.schedule.no_pkk_tug_boat"
                          v-uppercase
                          :state="errors.length > 0 ? false : null"
                          :readonly="permissionTenantAndSAL()"
                          placeholder="Cth: PKK.DN.IDSRI.2208.000123"
                        />
                        <!-- (!$can('manage', 'all') && $can('Hulu Migas', '')) && $router.currentRoute.params.id ? true : null -->

                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="
                            errorPath &&
                            errorMatchValue === form.schedule.no_pkk_tug_boat
                          "
                          class="text-danger"
                          >{{
                            errorPath === 'no_pkk_tug_boat' ? errorValue : ''
                          }}</small
                        >
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" cols="12" v-else>
                    <b-form-group label="Nomor PKK *" label-for="vi-no_pkk">
                      <!-- regex: /^[0-9]+$/ -->
                      <validation-provider
                        #default="{ errors }"
                        name=" Nomor PKK *"
                        :rules="{
                          required: true
                        }"
                      >
                        <b-form-input
                          id="vi-no_pkk"
                          v-model="form.tug_boat.no_pkk_tug_boat"
                          v-uppercase
                          :state="errors.length > 0 ? false : null"
                          :readonly="permissionSuggestionTugBoat()"
                          placeholder="Cth: PKK.DN.IDSRI.2208.000123"
                        />
                        <!-- (!$can('manage', 'all') && $can('Hulu Migas', '')) && $router.currentRoute.params.id ? true : null -->

                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="
                            errorPath &&
                            errorMatchValue === form.tug_boat.no_pkk_tug_boat
                          "
                          class="text-danger"
                          >{{
                            errorPath === 'no_pkk_tug_boat' ? errorValue : ''
                          }}</small
                        >
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                  <b-col md="6" cols="12">
                    <b-form-group
                      label="Perusahaan Shipping Agent *"
                      label-for="vi-dm_shipping_agent-shipping_name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name=" Perusahaan Shipping Agent *"
                        rules="required"
                      >
                        <vue-autosuggest
                          id="vi-dm_shipping_agent-shipping_name"
                          v-model="
                            form.tug_boat.dm_shipping_agent.shipping_name
                          "
                          v-uppercase
                          :get-suggestion-value="getSuggestionValue"
                          :suggestions="[
                            {
                              data:
                                form.tug_boat.dm_shipping_agent
                                  .dm_shipping_agents || []
                            }
                          ]"
                          :state="errors.length > 0 ? false : null"
                          :input-props="{
                            id: 'autosuggest__input',
                            class: 'form-control form-input',
                            placeholder: 'Cth: PT LINTAS SERAM M',
                            disabled: permissionSuggestionTugBoat()
                          }"
                          @focus="
                            getSuggestionValueFocus(
                              $event,
                              'tug_boat',
                              'dm_shipping_agent',
                              'dm_shipping_agent.shipping_name'
                            )
                          "
                          @input="
                            searchForm({
                              keyword: $event,
                              uri: 'dm_shipping_agent/',
                              model: 'tug_boat',
                              currentSuggest: 'tug_boat',
                              childSuggesst: 'dm_shipping_agent.shipping_name'
                            })
                          "
                        >
                          <!-- !form.tug_boat.dm_shipping_agent.isNew -->
                          <template slot-scope="{ suggestion }">
                            <div class="d-flex align-items-center">
                              <div class="detail ml-50">
                                <b-card-text class="mb-0">
                                  <strong>
                                    <span class="my-suggestion-item">{{
                                      suggestion.item.shipping_name
                                    }}</span>
                                  </strong>
                                </b-card-text>
                                <small class="text-muted">
                                  <strong
                                    ><span
                                      v-text="
                                        `${dateFormat(
                                          suggestion.item.created_at
                                        )}`
                                      "
                                  /></strong>
                                </small>
                              </div>
                            </div>
                          </template>
                          <template slot="before-suggestions">
                            <div class="ml-1 mt-1">
                              <b-badge
                                href="#"
                                pill
                                class="mr-1"
                                :variant="
                                  statusSuggestionColor[dm_shipping_agentState]
                                "
                              >
                                <span
                                  >
                                  {{
                                    statusSuggestionChoose[
                                      dm_shipping_agentState
                                    ]
                                  }}</span
                                >
                              </b-badge>
                              <b-badge variant="info">
                                Total:
                                {{
                                  form.tug_boat.dm_shipping_agent
                                    .dm_shipping_agents.length
                                }}
                              </b-badge>
                            </div>
                          </template>
                        </vue-autosuggest>
                        <small class="text-danger">{{ errors[0] }}</small>
                        <small
                          v-if="
                            errorPath &&
                            errorMatchValue ===
                              form.tug_boat.dm_shipping_agent.shipping_name
                          "
                          class="text-danger"
                          >{{
                            errorPath === 'shipping_name' ? errorValue : ''
                          }}</small
                        >
                      </validation-provider>
                      <b-spinner
                        v-if="dm_shipping_agentStateLoading"
                        class="suggest-loading"
                        small
                        variant="primary"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- show only on create data | action for suggestion value -->
                  <b-col
                    cols="12"
                    v-if="!isExistDetailID && !form.tug_boat.isNew"
                    class="text-left"
                  >
                    <div class="float-left">
                      <b-button
                        class="mr-1"
                        variant="outline-danger"
                        @click="breakSuggestionValueTB()"
                      >
                        <feather-icon
                          icon="PenToolIcon"
                          :style="{ cursor: 'pointer' }"
                          class="mr-50"
                        />
                        <span
                          class="align-middle"
                          v-text="
                            'Ubah Data Master Kapal Yang otomatis Teriisi ( Duplicate & Create as a New Data )'
                          "
                        />
                      </b-button>
                      <span
                        ><small
                          ><strong
                            >Abaikan jika data master kapal yang otomatis
                            teriisi sudah benar</strong
                          ></small
                        ></span
                      >
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="12" cols="12">
                <b-row>
                  <b-col
                    class="text-left"
                    md="6"
                    cols="12"
                    v-if="
                      ($store.state.app.roleName !== 'viewer' &&
                        $store.state.app.roleName !== 'jetty man' &&
                        $store.state.app.roleName !== 'security' &&
                        isExistDetailID &&
                        statePayloadDMKTB === null &&
                        !statePayloadDMKTBMaster) ||
                      statePayloadDMKTB ===
                        mapStatePayloadDMK.UPDATE_WITH_NEW_DATA
                    "
                  >
                    <b-button
                      variant="outline-danger"
                      class="mt-2"
                      block
                      :disabled="
                        !form.tug_boat.no_pkk_tug_boat || !editAble(user_id)
                      "
                      @click="
                        updateDMKTB({
                          isNotEdit: false,
                          payloadDMK: mapStatePayloadDMK.UPDATE_WITH_NEW_DATA
                        })
                      "
                    >
                      <feather-icon
                        icon="PenToolIcon"
                        :style="{ cursor: 'pointer' }"
                        class="mr-50"
                      />
                      <span class="align-middle"
                        >Duplicate data tug boat and Create as a new data</span
                      >
                    </b-button>
                    <span>Contoh kasus: ada pembaharuan nomor PKK</span>
                  </b-col>
                  <b-col
                    class="text-left"
                    md="6"
                    cols="12"
                    v-if="
                      ($store.state.app.roleName !== 'viewer' &&
                        $store.state.app.roleName !== 'jetty man' &&
                        $store.state.app.roleName !== 'security' &&
                        isExistDetailID &&
                        statePayloadDMKTB === null &&
                        !statePayloadDMKTBMaster) ||
                      statePayloadDMKTB ===
                        mapStatePayloadDMK.UPDATE_ON_EXIST_DATA
                    "
                  >
                    <b-button
                      variant="outline-warning"
                      class="mt-2"
                      block
                      :disabled="
                        !form.tug_boat.no_pkk_tug_boat || !editAble(user_id)
                      "
                      @click="
                        updateDMKTB({
                          isNotEdit: false,
                          payloadDMK: mapStatePayloadDMK.UPDATE_ON_EXIST_DATA
                        })
                      "
                    >
                      <feather-icon
                        icon="PenToolIcon"
                        :style="{ cursor: 'pointer' }"
                        class="mr-50"
                      />
                      <span class="align-middle"
                        >Revisi data tug boat, Jika ada data yang tidak
                        sesuai.</span
                      >
                    </b-button>
                    <span>Contoh kasus: kesalahan penulisan</span>
                  </b-col>
                  <b-col md="3" cols="12" v-if="statePayloadDMKTB">
                    <b-button
                      class="d-block mt-2"
                      variant="danger"
                      block
                      @click="
                        updateDMKTB({
                          isNotEdit: true,
                          payloadDMK: null
                        })
                        fetchData()
                      "
                    >
                      <feather-icon
                        icon="XCircleIcon"
                        :style="{ cursor: 'pointer' }"
                        class=""
                      />
                      <span class="align-middle" v-text="''" />
                    </b-button>
                  </b-col>
                  <b-col md="3" cols="12" v-if="statePayloadDMKTB">
                    <b-button
                      class="d-block mt-2"
                      variant="success"
                      block
                      :disabled="loadingDupRevTB"
                      @click="saveToUpdateDMKTB()"
                    >
                      <feather-icon
                        v-if="!loadingDupRevTB"
                        icon="CheckCircleIcon"
                        :style="{ cursor: 'pointer' }"
                        class="mr-50"
                      />
                      <b-spinner v-if="loadingDupRevTB" small></b-spinner>
                      <span class="align-middle" v-text="'SIMPAN'" />
                    </b-button>
                  </b-col>
                  <!-- <b-col class="text-right" md="12" cols="12">
                    <b-button
                      v-if="
                        $store.state.app.roleName !== 'viewer' &&
                        $store.state.app.roleName !== 'jetty man' &&
                        $store.state.app.roleName !== 'security' &&
                        isExistDetailID &&
                        !statePayloadDMKTB &&
                        !statePayloadDMKTBMaster
                      "
                      variant="primary"
                      class="mt-2"
                      block
                      @click="
                        updateDMKTBMaster({
                          isNotEdit: false,
                          payloadDMK: mapStatePayloadDMK.CHANGE_ON_EXIST_DATA
                        })
                      "
                    >
                      <feather-icon
                        icon="DatabaseIcon"
                        :style="{ cursor: 'pointer' }"
                        class="mr-50"
                      />
                      <span class="align-middle">Ganti/Pilih Kapal</span>
                    </b-button>
                  </b-col> -->
                  <!-- for ganti kapal tug boat cancel or save -->
                  <b-col md="2" cols="12" v-if="statePayloadDMKTBMaster">
                    <b-button
                      block
                      class="mt-2"
                      variant="danger"
                      @click="
                        updateDMKTBMaster({
                          isNotEdit: true,
                          payloadDMK: null
                        })
                        fetchData()
                      "
                    >
                      <feather-icon
                        icon="XCircleIcon"
                        :style="{ cursor: 'pointer' }"
                        class=""
                      />
                      <span></span>
                    </b-button>
                  </b-col>
                  <b-col md="3" cols="12" v-if="statePayloadDMKTBMaster">
                    <b-button
                      block
                      class="mt-2"
                      variant="success"
                      :disabled="loadingDupRevTB"
                      @click="jwtGantiKapalTB()"
                    >
                      <feather-icon
                        v-if="!loadingDupRevTB"
                        icon="CheckCircleIcon"
                        :style="{ cursor: 'pointer' }"
                        class="mr-50"
                      />
                      <b-spinner v-if="loadingDupRevTB" small></b-spinner>
                      <span>Simpan</span>
                    </b-button>
                  </b-col>
                  <!-- end of for ganti kapal tug boat cancel or save -->

                  <!-- schedule tug boat -->
                  <!-- v-if="form.schedule.jenis_kapal === 'Tug Boat'" -->
                  <b-col cols="12">
                    <div class="divider my-2">
                      <div class="divider-text">
                        <b-badge :variant="'info'">Schedule</b-badge>
                      </div>
                    </div>
                    <b-row>
                      <b-col
                        key="kegiatan1key"
                        :md="form.schedule.kegiatan !== 'BUNKER' ? '3' : '6'"
                        :cols="form.schedule.kegiatan !== 'BUNKER' ? '6' : '6'"
                        v-if="form.schedule.jenis_kapal"
                      >
                        <b-form-group
                          label="Pilih Kegiatan *"
                          label-for="vi-schedule-kegiatan"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Kegiatan"
                            vid="vi-schedule-kegiatan"
                            rules="required"
                          >
                            <v-select
                              id="vi-schedule-kegiatan"
                              v-model="form.schedule.kegiatan"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="
                                form.schedule.jenis_kapal !== 'Tug Boat'
                                  ? kegiatan
                                  : kegiatan.filter(
                                      (data) =>
                                        data.value === 'TAMBAT' ||
                                        data.value === 'BUNKER'
                                    )
                              "
                              :reduce="(option) => option.value"
                              :selectable="
                                (option) => !option.value.includes('null')
                              "
                              :disabled="permissionTenant()"
                              label="text"
                              placeholder="Pilih Kegiatan"
                              item-value="value"
                              item-text="text"
                              @search:focus="onFocusKegiatan()"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        key="kegiatan2key"
                        md="3"
                        cols="6"
                        v-if="
                          form.schedule.jenis_kapal &&
                          form.schedule.kegiatan !== 'BUNKER'
                        "
                      >
                        <b-form-group
                          label="Tambah Kegiatan"
                          label-for="vi-schedule-tambah-kegiatan"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Tambah Kegiatan"
                            vid="vi-schedule-tambah-kegiatan"
                            rules=""
                          >
                            <v-select
                              id="vi-schedule-tambah-kegiatan"
                              v-model="form.schedule.kegiatan_tambahan"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="
                                kegiatan.filter(
                                  (data) => data.value === 'BUNKER'
                                )
                              "
                              :reduce="(option) => option.value"
                              :selectable="
                                (option) =>
                                  !option.value.includes('null') &&
                                  option.value.includes('BUNKER')
                              "
                              :disabled="permissionTenant()"
                              label="text"
                              placeholder="Tambah Kegiatan Bunker"
                              item-value="value"
                              item-text="text"
                              @search:focus="onFocusKegiatan()"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col
                        md="3"
                        v-if="form.schedule.jenis_kapal === 'Tug Boat'"
                      >
                        <b-form-group
                          label="asal Kapal"
                          label-for="vi-asal_kapal"
                        >
                          <div class="suggest-custom">
                            <validation-provider
                              #default="{ errors }"
                              name="asal Kapal"
                              rules=""
                            >
                              <vue-autosuggest
                                id="vi-asal-kapal"
                                v-model="form.dm_asal_kapal.asal"
                                v-uppercase
                                :suggestions="[
                                  { data: form.dm_asal_kapal.dm_asal_kapals }
                                ]"
                                :get-suggestion-value="getSuggestionValue"
                                :input-props="{
                                  id: 'autosuggest__input',
                                  class: 'form-control',
                                  placeholder: 'asal Kapal'
                                }"
                                @focus="
                                  getSuggestionValueFocus(
                                    $event,
                                    'dm_asal_kapal',
                                    'asal'
                                  )
                                "
                                @input="
                                  searchForm({
                                    keyword: $event,
                                    uri: 'dm_asal_kapal/',
                                    model: 'dm_asal_kapal',
                                    currentSuggest: 'asal'
                                  })
                                "
                              >
                                <template slot-scope="{ suggestion }">
                                  <div class="d-flex align-items-center">
                                    <b-avatar
                                      :text="
                                        Array.from(suggestion.item.asal)[0] +
                                        Array.from(suggestion.item.asal)[1]
                                      "
                                      variant="primary"
                                    />
                                    <div class="detail ml-50">
                                      <b-card-text class="mb-0">
                                        <strong
                                          ><span
                                            v-text="`${suggestion.item.asal}`"
                                        /></strong>
                                        <br />
                                        <small class="text-muted">
                                          <strong
                                            ><span
                                              v-text="
                                                `Created AT ${formatDateTime(
                                                  suggestion.item.created_at
                                                )}`
                                              "
                                          /></strong>
                                        </small>
                                        <br />
                                      </b-card-text>
                                    </div>
                                  </div>
                                </template>
                                <template slot="before-suggestions">
                                  <div class="ml-1 mt-1 mb-1 mr-1">
                                    <b-badge
                                      href="#"
                                      pill
                                      :variant="
                                        statusSuggestionColor[
                                          dm_asal_kapalState
                                        ]
                                      "
                                      class="mr-1"
                                    >
                                      <span
                                        >
                                        {{
                                          statusSuggestionChoose[
                                            dm_asal_kapalState
                                          ]
                                        }}</span
                                      >
                                    </b-badge>
                                    <b-badge variant="info">
                                      Total:
                                      {{
                                        form.dm_asal_kapal.dm_asal_kapals.length
                                      }}
                                    </b-badge>
                                  </div>
                                </template>
                              </vue-autosuggest>

                              <small class="text-danger">{{ errors[0] }}</small>
                              <small
                                v-if="
                                  errorPath &&
                                  errorMatchValue === form.dm_asal_kapal.asal
                                "
                                class="text-danger"
                                >{{
                                  errorPath === 'asal' ? errorValue : ''
                                }}</small
                              >
                            </validation-provider>
                            <!-- <b-input-group-append is-text> -->
                            <b-spinner
                              v-if="dm_asal_kapalStateLoading"
                              class="suggest-loading"
                              small
                              variant="primary"
                            />
                          </div>
                        </b-form-group>
                      </b-col>

                      <b-col md="3" cols="6">
                        <!-- rencana sandar tug boat -->
                        <b-form-group
                          label="Rencana Sandar Tug Boat *"
                          label-for="vi-rencana_sandar"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Rencana Sandar Tug Boat *"
                            rules="required"
                          >
                            <flat-pickr
                              v-if="!permissionTenantAndSAL()"
                              v-model="form.tug_boat.rencana_sandar"
                              class="form-control"
                              static="true"
                              placeholder="Rencana Sandar Tug Boat *"
                              :state="errors.length > 0 ? false : null"
                              :config="
                                $router.currentRoute.params.id !== undefined
                                  ? dpconfigRevisi
                                  : dpconfig
                              "
                            />
                            <b-form-input
                              v-else
                              id="vi-rencana_sandar"
                              :value="
                                formatDateTime(form.tug_boat.rencana_sandar)
                              "
                              :readonly="
                                !$can('manage', 'all') && $can('Hulu Migas', '')
                              "
                              :state="errors.length > 0 ? false : null"
                            />
                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col md="6" cols="6">
                        <b-form-group
                          label="No Pindah"
                          label-for="vi-schedule-no_pindah_tb"
                        >
                          <!-- regex: /^[0-9]+$/ -->
                          <validation-provider
                            #default="{ errors }"
                            name="No Pindah"
                            vid="No Pindah"
                            :rules="{
                              required: false
                            }"
                          >
                            <b-form-input
                              id="vi-schedule-no_pindah_tb"
                              v-model="form.tug_boat.no_pindah_tb"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="'Nomor Pindah (opsional)'"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col md="3" cols="6">
                        <b-form-group
                          label="Rencana Posisi Jetty *"
                          label-for="vi-schedule-rencana_position_jetty"
                        >
                          <!-- regex: /^[0-9]+$/ -->
                          <validation-provider
                            #default="{ errors }"
                            name="Rencana Posisi Jetty"
                            vid="Rencana Posisi Jetty"
                            :rules="{
                              required: true
                            }"
                          >
                            <v-select
                              id="vi-schedule-rencana_position_jetty"
                              v-model="form.tug_boat.rencana_position_jetty"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="jetty_positions"
                              :reduce="(option) => option.value"
                              :selectable="
                                (option) => !option.value.includes('null')
                              "
                              :disabled="permissionTenantAndSAL()"
                              label="text"
                              placeholder="Pilih Rencana Posisi Jetty"
                              item-value="value"
                              item-text="text"
                              @search:focus="onFocusJetty()"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col md="3" cols="12">
                        <b-form-group
                          label="Rencana Baris Posisi Jetty *"
                          label-for="vi-schedule-rencana_baris_position_jetty"
                        >
                          <!-- regex: /^[0-9]+$/ -->
                          <validation-provider
                            #default="{ errors }"
                            name="Rencana Baris Posisi Jetty"
                            vid="Rencana Baris Posisi Jetty"
                            :rules="{
                              required: true
                            }"
                          >
                            <v-select
                              id="vi-schedule-rencana_baris_position_jetty"
                              v-model="
                                form.tug_boat.rencana_baris_position_jetty
                              "
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="jetty_baris_positions"
                              :reduce="(option) => option.value"
                              :selectable="
                                (option) => !option.value.includes('null')
                              "
                              :disabled="permissionTenantAndSAL()"
                              label="text"
                              placeholder="Pilih Rencana Baris Posisi Jetty"
                              item-value="value"
                              item-text="text"
                              @search:focus="onFocusJetty()"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col
                        class="text-center"
                        md="12"
                        cols="12"
                        v-if="
                          $store.state.app.roleName !== 'viewer' &&
                          $store.state.app.roleName !== 'jetty man' &&
                          $store.state.app.roleName !== 'security'
                        "
                      >
                        <ExpirationAlert
                          v-if="disableActionButton()"
                        />
                        <b-button
                          v-else
                          variant="primary"
                          class="mt-2"
                          block
                          :disabled="loadingTab1 || !editAble(user_id)"
                          @click="updateTab1()"
                        >
                          <feather-icon
                            v-if="!loadingTab1"
                            icon="DatabaseIcon"
                            :style="{ cursor: 'pointer' }"
                            class="mr-50"
                          />
                          <b-spinner v-if="loadingTab1" small></b-spinner>
                          <span class="align-middle"
                            >Simpan / Ubah Main Boat</span
                          >
                        </b-button>
                      </b-col>
                    </b-row>
                  </b-col>
                </b-row>
              </b-col>
            </b-row>
          </validation-observer>
          <!-- END OF TUG BOAT -->
          <div class="mb-1" />
        </tab-content>


        <!-- TAB FORM VISITOR | Jumlah Crew Kapal -->
        <!-- <tab-content title="Crew Kapal" icon="feather icon-anchor">
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Crew Kapal</h5>
              <small class="text-muted">
                <strong
                  >Untuk keperluan monitoring jumlah crew kapal yang masuk area
                  PT SAL.</strong
                >
              </small>
            </b-col>
          </b-row>
          <validation-observer ref="refVisitor" tag="form">
            <b-row>
              <b-col md="6" cols="12">
                <b-card>
                  <b-row>
                    <b-col md="12" cols="12">
                      <b-form-group
                        label="Jumlah Crew Kapal"
                        label-for="vi-schedule-count_crew_tb"
                      >
                        <validation-provider
                          #default="{ errors }"
                          name="Jumlah Crew Kapal"
                          vid="Jumlah Crew Kapal"
                          :rules="{
                            required: false
                          }"
                        >
                          <b-form-input
                            id="vi-schedule-count_crew_tb"
                            v-model="form.schedule.count_crew_tb"
                            :state="errors.length > 0 ? false : null"
                            type="number"
                            :readonly="permissionTenant()"
                            :placeholder="'Jumlah Crew Kapal'"
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <b-button
              v-if="isExistDetailID && !permissionTenantAndSAL()"
              variant="gradient-primary"
              class="mt-2"
              block
              :disabled="loadingRealisasiCrew"
              @click="saveCrewKapal()"
            >
              <feather-icon
                v-if="!loadingRealisasiCrew"
                icon="CheckCircleIcon"
                :style="{ cursor: 'pointer' }"
                class="mr-50"
              />
              <b-spinner v-if="loadingRealisasiCrew" small></b-spinner>
              <span class="align-middle">Simpan Jumlah Crew Kapal</span>
            </b-button>
          </validation-observer>
          <div class="mb-1" />
        </tab-content> -->


        <!-- TAB SPOG -->
        <tab-content
          v-if="dataID && form.schedule.kegiatan !== 'BUNKER'"
          title="SPOG"
          icon="feather icon-unlock"
        >
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">SPOG</h5>
              <small class="text-muted">
                <strong>Document Realisasi SPOG.</strong>
              </small>
            </b-col>
          </b-row>
          <span>
            <validation-observer ref="refSAL" tag="form">
              <b-row>
                <b-col
                  v-if="
                    ($can('manage', 'all') ||
                      $can('SAL', '') ||
                      $router.currentRoute.params.id) &&
                    form.tug_boat.user?.abilities[0]?.action == 'TBBM JETTY 3A'
                  "
                  md="12"
                  cols="12"
                >
                  <b-form-group
                    label="Nomor SPOG"
                    label-for="vi-schedule-no_spog"
                  >
                    <!-- regex: /^[0-9]+$/ -->
                    <validation-provider
                      #default="{ errors }"
                      name="Nomor SPOG"
                      vid="Nomor SPOG"
                      rules="required"
                    >
                      <b-form-input
                        id="vi-schedule-no_spog"
                        v-model="form.schedule.no_spog_tb"
                        :state="errors.length > 0 ? false : null"
                        :readonly="permissionTenant()"
                        :placeholder="
                          $router.currentRoute.params.id
                            ? ''
                            : 'Opsional'
                        "
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  v-if="
                    ($can('manage', 'all') ||
                      $can('SAL', '') ||
                      $router.currentRoute.params.id) &&
                    form.tug_boat.user?.abilities[0]?.action != 'TBBM JETTY 3A'
                  "
                  md="12"
                  cols="12"
                >
                  <b-form-group
                    label="Nomor SPOG ( Kedatangan )"
                    label-for="vi-schedule-no_spog"
                  >
                    <!-- regex: /^[0-9]+$/ -->
                    <validation-provider
                      #default="{ errors }"
                      name="Nomor SPOG ( Kedatangan )"
                      vid="Nomor SPOG ( Kedatangan )"
                      rules="required"
                    >
                      <b-form-input
                        id="vi-schedule-no_spog"
                        v-model="form.schedule.no_spog_tb"
                        :state="errors.length > 0 ? false : null"
                        :readonly="permissionTenant()"
                        :placeholder="
                          $router.currentRoute.params.id
                            ? ''
                            : 'Opsional'
                        "
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col
                  md="12"
                  cols="12"
                  v-if="
                    ($can('manage', 'all') ||
                      $can('SAL', '') ||
                      $router.currentRoute.params.id) &&
                    form.tug_boat.user?.abilities[0]?.action != 'TBBM JETTY 3A'
                  "
                >
                  <b-form-group
                    label="Nomor SPOG ( keberangkatan )"
                    label-for="vi-schedule-no_spog_tb_brk"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nomor SPOG ( keberangkatan )"
                      vid="Nomor SPOG ( keberangkatan )"
                      :rules="{
                        required: false
                      }"
                    >
                      <b-form-input
                        id="vi-schedule-no_spog_tb_brk"
                        v-model="form.schedule.no_spog_tb_brk"
                        :state="errors.length > 0 ? false : null"
                        placeholder="Nomor SPOG ( Keberangkatan )"
                        />
                        <!-- :readonly="permissionTenantAndSAL()" -->

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- TODO: HIDE UPLOAD SPOG --> <!-- hulu migas -->
                <!-- v-if="
                  $can('manage', 'all') ||
                  $can('SAL', '') ||
                  $router.currentRoute.params.id
                " -->
                <b-col
                  v-if="
                    ($can('manage', 'all') ||
                    $can('SAL', '') ||
                    $router.currentRoute.params.id) && form.tug_boat.user?.abilities[0]?.action === 'TBBM JETTY 3A'
                  "
                  md="12"
                  cols="12"
                >
                <!-- TODO: END OF HIDE UPLOAD SPOG --> <!-- hulu migas -->
                  <b-form-group
                    label="File SPOG"
                    label-for="vi-schedule-no_spog_file"
                  >
                    <!-- <validation-provider
                      #default="{ errors }"
                      name="Nomor SPOG FILE"
                      vid="Nomor SPOG FILE"
                      rules=""
                    > -->
                    <!-- :state="errors.length > 0 ? false : null" -->
                    <BFormFile
                      id="vi-schedule-no_spog_file"
                      v-model="form.schedule.no_spog_file_tb"
                      placeholder="Choose a file SPOG or drop it here..."
                      drop-placeholder="Drop file here..."
                      no-drop
                      accept=".xls, .xlsx, .pdf, .jpeg, .jpg, .png"
                    />
                    <!-- <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider> -->
                  </b-form-group>
                  <app-timeline-item
                    v-for="(item, index) in no_spog_file_tb"
                    :key="index"
                  >
                    <div
                      class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                    ></div>
                    <p>
                      <b-img
                        :src="require('@/assets/images/icons/xls.png')"
                        height="auto"
                        width="20"
                        class="mr-1"
                      />
                      <span class="align-bottom">
                        <a
                          :href="`${BACKEND_URL}${item.path}/${item.unique_name}`"
                          target="_blank"
                          style="color: grey"
                          rel="noopener noreferrer"
                          >{{ item.original_name }}</a
                        >
                      </span>
                    </p>
                    <small class="text-muted"
                      >Size {{ item.size }}
                      <strong>[ click name file, to preview.]</strong></small
                    >
                  </app-timeline-item>
                </b-col>

                <b-col
                  class="text-right"
                  md="12"
                  cols="12"
                  v-if="$store.state.app.roleName !== 'viewer'"
                >
                  <b-alert
                    :variant="
                      !form.schedule.no_rpkro_tb ? 'warning' : 'primary'
                    "
                    show
                    class="mt-1 cursor-pointer"
                  >
                    <div
                      class="alert-body"
                      @click="copyText(form.schedule.no_rpkro_tb)"
                    >
                      <strong>
                        <feather-icon icon="AnchorIcon" class="mr-25" />
                        <span
                          v-text="
                            !form.schedule.no_rpkro_tb
                              ? 'Nomor RPKRO Belum terbit'
                              : `Nomor RPKRO : ${form.schedule.no_rpkro_tb}`
                          "
                        />
                      </strong>
                    </div>
                  </b-alert>
                  <!-- form.schedule.no_rpkro_tb && -->
                  <b-button
                    v-if="isExistDetailID && !permissionTenant()"
                    variant="gradient-primary"
                    class="mt-2"
                    block
                    :disabled="loadingRealisasiKegiatan || !editAble(user_id)"
                    @click="saveRealisasiKegiatan('SPOG')"
                  >
                    <feather-icon
                      v-if="!loadingRealisasiKegiatan"
                      icon="CheckCircleIcon"
                      :style="{ cursor: 'pointer' }"
                      class="mr-50"
                    />
                    <b-spinner
                      v-if="loadingRealisasiKegiatan"
                      small
                    ></b-spinner>
                    <span class="align-middle">Simpan Realisasi Kegiatan</span>
                  </b-button>
                </b-col>
              </b-row>
            </validation-observer>
          </span>
        </tab-content>

        <!-- TAB Ijin Bunker -->

        <tab-content
          id="ijin-bunker"
          title="Ijin Bunker"
          :icon="wizard.first.step4.icon"
          v-if="
            form.schedule.jenis_kapal &&
            (form.schedule.kegiatan === 'BUNKER' ||
              form.schedule.kegiatan_tambahan === 'BUNKER')
          "
        >
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">Document File Ijin Bunker</h5>
              <small class="text-muted">
                <strong>{{ wizard.first.step4.content.subtitle }}.</strong>
              </small>
            </b-col>
          </b-row>
          <b-spinner v-if="loadingFileStorage" small></b-spinner>
          <validation-observer ref="tbbmRulesFileBunker" tag="form">
            <!-- name="File/Attachments/Document ( RKBM*, PBMBB, dan BL )" -->

            <b-row>
              <b-col md="12" cols="12">
                <b-form-group
                  label="Nomor Ijin Bunker"
                  label-for="vi-schedule-no_ijin_bunker"
                >
                  <!-- regex: /^[0-9]+$/ -->
                  <validation-provider
                    #default="{ errors }"
                    name="Nomor Ijin Bunker"
                    vid="Nomor Ijin Bunker"
                    :rules="{
                      required: true
                    }"
                  >
                    <b-form-input
                      id="vi-schedule-no_ijin_bunker"
                      v-model="form.schedule.no_ijin_bunker"
                      :state="errors.length > 0 ? false : null"
                      :readonly="permissionTenant()"
                      :placeholder="
                        $router.currentRoute.params.id
                          ? 'Nomor Ijin BUnker'
                          : 'Nomor Ijin BUnker'
                      "
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" cols="12">
                <!-- lct -->
                <b-form-group
                  label="Volume (Total Bunker)"
                  label-for="vi-dm_river_boat-total_bunker"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Volume (Total Bunker) *"
                    rules="required"
                  >
                    <b-input-group append="Liter">
                      <cleave
                        id="vi-dm_river_boat-total_bunker"
                        v-model="form.schedule.total_bunker"
                        class="form-control"
                        :raw="true"
                        :options="options.number"
                        :readonly="permissionTenantChild()"
                        placeholder="1.000 (dot automaticaly)"
                      />
                    </b-input-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col md="12" cols="12">
                <b-form-group
                  label="Expired Date Ijin Bunker"
                  label-for="vi-exp_ijin_bunker"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Expired Date Ijin Bunker *"
                    rules="required"
                  >
                    <flat-pickr
                      v-if="
                        (!$can('manage', 'special') ||
                        $can('manage', 'all') ||
                        $can('SAL', '')) && !disableActionButton()
                      "
                      v-model="form.schedule.exp_ijin_bunker"
                      class="form-control"
                      static="true"
                      placeholder="Expired Date Ijin Bunker"
                      :state="errors.length > 0 ? false : null"
                      :config="
                        $router.currentRoute.params.id !== undefined
                          ? dpExpIjinBunkerID
                          : dpExpIjinBunker
                      "
                      @on-change="onStartChangedpExpIjinBunker"
                    />
                    <b-form-input
                      v-else
                      id="vi-exp_ijin_bunker"
                      :value="formatDateTime(form.schedule.exp_ijin_bunker, 'D MMMM YYYY')"
                      :readonly="permissionTenant()"
                      :state="errors.length > 0 ? false : null"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col>
                <b-form-group label="" label-for="vi-schedule-ijin_bunker">
                  <validation-provider
                    #default="{ errors }"
                    name="Ijin Bunker"
                    vid="Ijin Bunker"
                    :rules="{
                      required: true
                    }"
                  >
                    <BFormFile
                      v-model="form.schedule.no_ijin_bunker_file"
                      placeholder="Choose a file Ijin Bunker or drop it here..."
                      drop-placeholder="Drop file here..."
                      :state="errors.length > 0 ? false : null"
                      accept=".xls, .xlsx, .pdf, .jpeg, .jpg, .png"
                      no-drop
                    />
                    <small class="text-danger">{{ errors[0] }}</small>

                    <app-timeline-item
                      v-for="(item, index) in no_ijin_bunker_file"
                      :key="index"
                    >
                      <div
                        class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
                      ></div>
                      <p>
                        <b-img
                          :src="require('@/assets/images/icons/xls.png')"
                          height="auto"
                          width="20"
                          class="mr-1"
                        />
                        <span class="align-bottom">
                          <a
                            :href="item.download_link"
                            target="_blank"
                            style="color: grey"
                            rel="noopener noreferrer"
                            >{{ item.original_name }}</a
                          >
                        </span>
                      </p>
                      <small class="text-muted"
                        >Size {{ item.size }}
                        <strong>[ click name file, to preview.]</strong></small
                      >
                    </app-timeline-item>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                class="text-center"
                md="12"
                cols="12"
                v-if="!permissionTenant() && isExistDetailID"
              >
                <br />
                <ExpirationAlert
                  v-if="disableActionButton()"
                />
                <b-button
                  variant="gradient-primary"
                  @click="saveIjinBunker()"
                  block
                  :disabled="loadingSaveTenant || !editAble(user_id)"
                  v-else-if="
                    $store.state.app.roleName !== 'viewer' &&
                    $store.state.app.roleName !== 'jetty man'
                  "
                >
                  <feather-icon
                    v-if="!loadingSaveTenant"
                    icon="CheckCircleIcon"
                    :style="{ cursor: 'pointer' }"
                    class="mr-50"
                  />
                  <b-spinner v-if="loadingSaveTenant" small></b-spinner>
                  <span class="align-middle" v-text="'Simpan Ijin Bunker'" />
                </b-button>
                <!-- </div> -->
              </b-col>
              <b-col
                v-if="
                  $can('manage', 'all') ||
                  $can('manage', 'special') ||
                  $can('SAL', '') ||
                  $router.currentRoute.params.id
                "
                md="12"
                cols="12"
              >
                <br />
                <b-card
                  v-if="
                    $can('manage', 'all') ||
                    $can('SAL', '') ||
                    $router.currentRoute.params.id
                  "
                >
                  <h4 class="mb-0">Realisasi Bunker</h4>
                  <hr />
                  <b-row>
                    <b-col
                      v-if="
                        $can('manage', 'all') ||
                        $can('manage', 'special') ||
                        $can('SAL', '') ||
                        $router.currentRoute.params.id
                      "
                      md="6"
                      cols="6"
                    >
                      <b-form-group
                        label="Waktu Mulai Berkegiatan Bunker"
                        label-for="vi-schedule-kegiatan_start_time_bunker"
                      >
                        <!-- regex: /^[0-9]+$/ -->
                        <validation-provider
                          #default="{ errors }"
                          name="Waktu Mulai Berkegiatan Bunker"
                          vid="Waktu Mulai Berkegiatan Bunker"
                          :rules="{
                            required_if: 'Waktu Selesai Berkegiatan Bunker'
                          }"
                        >
                          <flat-pickr
                            v-if="readonlyFieldTabAdminSAL()"
                            v-model="form.schedule.kegiatan_start_time_bunker"
                            class="form-control"
                            static="true"
                            placeholder=""
                            :state="errors.length > 0 ? false : null"
                            :config="
                              $router.currentRoute.params.id !== undefined
                                ? dpWktBunkerID
                                : dpWktBunker
                            "
                            @on-change="onStartChangedpWktBunker"
                          />
                          <b-form-input
                            v-else
                            id="vi-schedule-kegiatan_start_time_bunker"
                            :value="
                              formatDateTime(
                                form.schedule.kegiatan_start_time_bunker
                              )
                            "
                            :state="errors.length > 0 ? false : null"
                            :readonly="
                              (!$can('manage', 'all') &&
                                $can('manage', 'special')) ||
                              !$can('SAL', '')
                            "
                            placeholder=""
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      v-if="
                        $can('manage', 'all') ||
                        $can('manage', 'special') ||
                        $can('SAL', '') ||
                        $router.currentRoute.params.id
                      "
                      md="6"
                      cols="6"
                    >
                      <b-form-group
                        label="Waktu Selesai Berkegiatan Bunker"
                        label-for="vi-schedule-kegiatan_end_time_bunker"
                      >
                        <!-- regex: /^[0-9]+$/ -->
                        <validation-provider
                          #default="{ errors }"
                          name="Waktu Selesai Berkegiatan Bunker"
                          vid="Waktu Selesai Berkegiatan Bunker"
                          :rules="{
                            required_if: 'Waktu Berangkat'
                          }"
                        >
                          <flat-pickr
                            v-if="readonlyFieldTabAdminSAL()"
                            v-model="form.schedule.kegiatan_end_time_bunker"
                            class="form-control"
                            static="true"
                            placeholder=""
                            :state="errors.length > 0 ? false : null"
                            :config="dpWktBunker.end"
                            @on-change="onEndChangedpWktBunker"
                          />
                          <b-form-input
                            v-else
                            id="vi-schedule-kegiatan_end_time_bunker"
                            :value="
                              formatDateTime(
                                form.schedule.kegiatan_end_time_bunker
                              )
                            "
                            :state="errors.length > 0 ? false : null"
                            :readonly="
                              (!$can('manage', 'all') &&
                                $can('manage', 'special')) ||
                              !$can('SAL', '')
                            "
                            placeholder=""
                          />

                          <small class="text-danger">{{ errors[0] }}</small>
                        </validation-provider>
                      </b-form-group>
                    </b-col>
                    <b-col
                      class="text-center"
                      md="12"
                      cols="12"
                      v-if="
                        $can('manage', 'all') ||
                        (($can('SAL', '') || $can('TBBM JETTY 3A', '')) && $router.currentRoute.params.id)
                      "
                    >
                      <br />
                      <ExpirationAlert
                        v-if="disableActionButton()"
                      />
                      <b-button
                        v-else
                        variant="gradient-primary"
                        @click="saveRealisasiBunker()"
                        block
                        :disabled="loadingSaveTenant"
                      >
                        <!-- v-if="
                          $store.state.app.roleName !== 'viewer' &&
                          $store.state.app.roleName !== 'jetty man'
                        " -->
                        <feather-icon
                          v-if="!loadingSaveTenant"
                          icon="CheckCircleIcon"
                          :style="{ cursor: 'pointer' }"
                          class="mr-50"
                        />
                        <b-spinner v-if="loadingSaveTenant" small></b-spinner>
                        <span
                          class="align-middle"
                          v-text="'Simpan Realisasi Bunker'"
                        />
                      </b-button>
                      <!-- </div> -->
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
          </validation-observer>

          <div class="mb-1" />
        </tab-content>


        <!-- JETTY MAN -->
        <tab-content
          v-if="
            permissionSalOnly() ||
            $store.state.app.roleName === 'jetty man' ||
            $store.state.app.roleName === 'security' || $can('TBBM JETTY 3A', '')
          "
          :title="wizard.second.step1.title"
          :icon="wizard.second.step1.icon"
        >
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">{{ wizard.second.step1.content.title }}</h5>
              <small class="text-muted">
                <strong>{{ wizard.second.step1.content.subtitle }}.</strong>
              </small>
            </b-col>
          </b-row>
          <!-- TUG BOAT RKA2 -->
          <validation-observer
            ref="refRKA2"
            tag="form"
            v-if="form.tug_boat.user?.abilities.some(ability => ability.action === 'TBBM JETTY 3A')"
          >
            <b-row>
              <b-col cols="12" v-if="isExistDetailID">
                <div class="divider my-2">
                  <div class="divider-text">
                    <b-badge :variant="'info'">Tug Boat</b-badge>
                    <h3>Realisasi Kapal {{ form.tug_boat.name_tug_boat }}</h3>
                  </div>
                </div>
              </b-col>
              <b-col
                v-if="
                  $can('manage', 'all') ||
                  $can('SAL', '') ||
                  $router.currentRoute.params.id
                "
                :md="!permissionSalOnly() ? '6' : '6'"
                cols="12"
              >
                <b-form-group
                  label="Posisi Jetty"
                  label-for="vi-schedule-position_jetty"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Posisi Jetty"
                    vid="Posisi Jetty"
                    :rules="{
                      required_if: 'Waktu Kapal Sandar'
                    }"
                  >
                    <v-select
                      id="vi-schedule-position_jetty"
                      v-model="form.schedule.position_jetty"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="jetty_positions"
                      :reduce="(option) => option.value"
                      :selectable="(option) => !option.value.includes('null')"
                      :disabled="
                        (!$can('manage', 'all') && $can('manage', 'special')) ||
                        (!$can('SAL', '') &&
                          !$store.state.app.roleName === 'jetty man') || $can('TBBM JETTY 3A', '')
                      "
                      label="text"
                      placeholder="Pilih Posisi Jetty"
                      item-value="value"
                      item-text="text"
                      @search:focus="onFocusJetty()"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="
                  $can('manage', 'all') ||
                  $can('SAL', '') ||
                  $router.currentRoute.params.id
                "
                :md="!permissionSalOnly() ? '6' : '6'"
                cols="12"
              >
                <b-form-group
                  label="Posisi Baris Jetty"
                  label-for="vi-schedule-position_jetty"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="Posisi Baris Jetty"
                    vid="Posisi Baris Jetty"
                    :rules="{
                      required_if: 'Waktu Kapal Sandar'
                    }"
                  >
                    <v-select
                      id="vi-schedule-position_jetty"
                      v-model="form.schedule.position_baris_jetty"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="jetty_baris_positions"
                      :reduce="(option) => option.value"
                      :selectable="(option) => !option.value.includes('null')"
                      :disabled="
                        (!$can('manage', 'all') && $can('manage', 'special')) ||
                        (!$can('SAL', '') &&
                          $store.state.app.roleName !== 'jetty man' &&
                          $store.state.app.roleName !== 'security') || $can('TBBM JETTY 3A', '')
                      "
                      label="text"
                      placeholder="Pilih Posisi Baris Jetty"
                      item-value="value"
                      item-text="text"
                      @search:focus="onFocusJetty()"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="isExistDetailID"
                :md="permissionSalOnly() ? '4' : '4'"
                cols="6"
              >
                <b-form-group
                  label="Waktu Kapal Sandar"
                  label-for="vi-tug_boat-tug_sandar_start_time"
                >
                  <!-- regex: /^[0-9]+$/ -->
                  <validation-provider
                    #default="{ errors }"
                    name="Waktu Kapal Sandar"
                    vid="Waktu Kapal Sandar"
                    :rules="{
                      required_if: 'Waktu Kapal Berangkat'
                    }"
                  >
                    <flat-pickr
                      v-if="
                        permissionSalOnly() ||
                        $store.state.app.roleName === 'jetty man' ||
                        $store.state.app.roleName === 'security'
                      "
                      class="form-control"
                      v-model="form.tug_boat.tug_sandar_start_time"
                      static="true"
                      placeholder="WAKTU KAPAL SANDAR"
                      :state="errors.length > 0 ? false : null"
                      :config="dpconfig"
                      @on-change="onStartChange"
                    />
                    <b-form-input
                      v-else
                      id="vi-tug_boat-tug_sandar_start_time"
                      :value="
                        formatDateTime(form.tug_boat.tug_sandar_start_time)
                      "
                      :state="errors.length > 0 ? false : null"
                      :readonly="permissionSalOnly() || $can('TBBM JETTY 3A', '')"
                      placeholder="Diisi Admin SAL | Jetty Man"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col
                v-if="
                  $can('manage', 'all') ||
                  $can('SAL', '') ||
                  $router.currentRoute.params.id
                "
                :md="permissionSalOnly() ? '4' : '4'"
                cols="12"
              >
                <b-form-group label="Tujuan Kapal" label-for="vi-asal_kapal">
                  <div class="suggest-custom">
                    <validation-provider
                      #default="{ errors }"
                      name="Tujuan Kapal"
                      rules=""
                    >
                      <vue-autosuggest
                        id="vi-tujuan-kapal"
                        v-model="form.schedule.tujuan_kapal_tb"
                        v-uppercase
                        :suggestions="[
                          { data: form.dm_asal_kapal.dm_asal_kapals }
                        ]"
                        :get-suggestion-value="getSuggestionValueTKTB"
                        :input-props="{
                          id: 'autosuggest__input',
                          class: 'form-control',
                          placeholder: 'Tujuan Kapal',
                          disabled: $can('TBBM JETTY 3A', ''),
                        }"
                        @focus="
                          getSuggestionValueFocus(
                            $event,
                            'schedule',
                            'tujuan_kapal_tb'
                          )
                        "
                        @input="
                          searchForm({
                            keyword: $event,
                            uri: 'dm_asal_kapal/',
                            model: 'dm_asal_kapal',
                            currentSuggest: 'asal',
                            flagTujuanOps: 'tujuan_kapal_tb_is_new'
                          })
                        "
                      >
                        <template slot-scope="{ suggestion }">
                          <div class="d-flex align-items-center">
                            <b-avatar
                              :text="
                                Array.from(suggestion.item.asal)[0] +
                                Array.from(suggestion.item.asal)[1]
                              "
                              variant="primary"
                            />
                            <div class="detail ml-50">
                              <b-card-text class="mb-0">
                                <strong
                                  ><span v-text="`${suggestion.item.asal}`"
                                /></strong>
                                <br />
                                <small class="text-muted">
                                  <strong
                                    ><span
                                      v-text="
                                        `Created AT ${formatDateTime(
                                          suggestion.item.created_at
                                        )}`
                                      "
                                  /></strong>
                                </small>
                                <br />
                              </b-card-text>
                            </div>
                          </div>
                        </template>
                        <template slot="before-suggestions">
                          <div class="ml-1 mt-1 mb-1 mr-1">
                            <b-badge
                              href="#"
                              pill
                              :variant="
                                statusSuggestionColor[dm_asal_kapalState]
                              "
                              class="mr-1"
                            >
                              <span
                                >
                                {{
                                  statusSuggestionChoose[dm_asal_kapalState]
                                }}</span
                              >
                            </b-badge>
                            <b-badge variant="info">
                              Total:
                              {{ form.dm_asal_kapal.dm_asal_kapals.length }}
                            </b-badge>
                          </div>
                        </template>
                      </vue-autosuggest>

                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="
                          errorPath &&
                          errorMatchValue === form.dm_asal_kapal.asal
                        "
                        class="text-danger"
                        >{{ errorPath === 'asal' ? errorValue : '' }}</small
                      >
                    </validation-provider>
                    <!-- <b-input-group-append is-text> -->
                    <b-spinner
                      v-if="dm_asal_kapalStateLoading"
                      class="suggest-loading"
                      small
                      variant="primary"
                    />
                  </div>
                </b-form-group>
              </b-col>

              <b-col
                v-if="isExistDetailID"
                :md="permissionSalOnly() ? '4' : '4'"
                cols="6"
              >
                <b-form-group
                  label="Waktu Kapal Berangkat"
                  label-for="vi-tug_boat-tug_sandar_end_time"
                >
                  <!-- regex: /^[0-9]+$/ -->
                  <validation-provider
                    #default="{ errors }"
                    name="Waktu Kapal Berangkat"
                    vid="Waktu Kapal Berangkat"
                    :rules="{
                      required: false
                    }"
                  >
                    <flat-pickr
                      v-if="
                        permissionSalOnly() ||
                        $store.state.app.roleName === 'security' ||
                        $store.state.app.roleName === 'jetty man'
                      "
                      v-model="form.tug_boat.tug_sandar_end_time"
                      class="form-control"
                      static="true"
                      placeholder="WAKTU KAPAL BERANGKAT"
                      :state="errors.length > 0 ? false : null"
                      :config="configs.end"
                      @on-change="onEndChange"
                    />

                    <b-form-input
                      v-else
                      id="vi-tug_boat-tug_sandar_end_time"
                      :value="formatDateTime(form.tug_boat.tug_sandar_end_time)"
                      :state="errors.length > 0 ? false : null"
                      :readonly="permissionSalOnly() || $can('TBBM JETTY 3A', '')"
                      placeholder="Diisi Admin SAL | Jetty Man"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                class="text-right"
                md="12"
                cols="12"
                v-if="$store.state.app.roleName !== 'viewer'"
              >
                <b-button
                  v-if="
                    (isExistDetailID && permissionSalOnly()) ||
                    $store.state.app.roleName === 'jetty man' ||
                    $store.state.app.roleName === 'security'
                  "
                  variant="gradient-primary"
                  block
                  :disabled="
                    loadingRelRKA2
                  "
                  @click="saveRKA2()"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    :style="{ cursor: 'pointer' }"
                    class="mr-50"
                    v-if="!loadingRelRKA2"
                  />
                  <b-spinner v-if="loadingRelRKA2" small></b-spinner>
                  <span class="align-middle">Simpan Realisasi Sandar</span>
                </b-button>
              </b-col>
              <!-- <span class="ml-1" v-if="isExistDetailID"
                >Data Tug Boat dapat juga dilihat/diubah melalui Menu
              </span> -->
              <!-- <BBreadcrumb
                v-if="isExistDetailID"
                class="ml-0"
                :items="[
                  {
                    text: 'Terbitkan Form',
                    active: true
                  },
                  {
                    text: 'Jalur Sungai',
                    active: true
                  },
                  {
                    text: 'Tug Boat',
                    active: true
                  }
                ]"
              /> -->
              <b-col
                cols="12"
                v-if="
                  isExistDetailID &&
                  (!form.schedule.position_jetty ||
                    !form.schedule.position_baris_jetty)
                "
              >
                <b-alert variant="warning" show class="mb-0 mt-1">
                  <div class="alert-body">
                    <feather-icon icon="AlertTriangleIcon" class="mr-25" />
                    Belum realisasi posisi jetty
                  </div>
                </b-alert>
              </b-col>
            </b-row>
          </validation-observer>

          <!-- TUG BOAT RKA2 khusus hulu migas -->
          <validation-observer
            ref="refRKA222"
            tag="form"
            v-if="form.tug_boat.user?.abilities.some(ability => ability.action === 'Hulu Migas')"
          >
            <!-- TUG BOAT RKA2 -->
            <b-row class="p-3">
              <b-col cols="12" v-if="isExistDetailID">
                <div class="divider my-2">
                  <div class="divider-text">
                    <!-- <b-badge :variant="'info'">RKA 2</b-badge> -->
                    <b-badge :variant="'info'">Tug Boat</b-badge>
                    <h3>
                      Realisasi Kapal {{ this.form.tug_boat.name_tug_boat }}
                    </h3>
                    <span>{{ this?.form_realisasi?.rka2 }}</span>
                    <br />
                    <span>Sandar → Berangkat</span>
                  </div>
                </div>
              </b-col>
              <b-col
                v-if="isExistDetailID"
                :md="
                  ($store.state.app.roleName !== 'viewer' &&
                    permissionSalOnly()) ||
                  $store.state.app.roleName === 'jetty man'
                    ? '3'
                    : '3'
                "
                cols="6"
              >
                <b-form-group
                  label="Waktu Kapal Sandar"
                  label-for="vi-tug_boat-tug_sandar_start_time"
                >
                  <!-- regex: /^[0-9]+$/ -->
                  <validation-provider
                    #default="{ errors }"
                    name="Waktu Kapal Sandar"
                    vid="Waktu Kapal Sandar"
                    :rules="{
                      required_if: 'Waktu Kapal Berangkat'
                    }"
                  >
                    <flat-pickr
                      v-if="
                        permissionSalOnly() ||
                        $store.state.app.roleName === 'jetty man' ||
                        $store.state.app.roleName === 'security'
                      "
                      v-model="form.tug_boat.tug_sandar_start_time"
                      class="form-control"
                      static="true"
                      placeholder="WAKTU KAPAL SANDAR"
                      :state="errors.length > 0 ? false : null"
                      :config="dpconfig"
                      @on-change="onStartChange"
                    />
                    <b-form-input
                      v-else
                      id="vi-tug_boat-tug_sandar_start_time"
                      :value="
                        formatDateTime(form.tug_boat.tug_sandar_start_time)
                      "
                      :state="errors.length > 0 ? false : null"
                      :readonly="permissionSalOnly()"
                      placeholder="Diisi Admin SAL | Jetty Man"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                v-if="
                  $can('manage', 'all') ||
                  $can('SAL', '') ||
                  $router.currentRoute.params.id
                "
                :md="permissionSalOnly() ? '3' : '3'"
                cols="12"
              >
                <b-form-group label="Tujuan Kapal" label-for="vi-asal_kapal">
                  <div class="suggest-custom">
                    <validation-provider
                      #default="{ errors }"
                      name="Tujuan Kapal"
                      rules=""
                    >
                      <vue-autosuggest
                        id="vi-tujuan-kapal"
                        v-model="form.schedule.tujuan_kapal_tb"
                        v-uppercase
                        :suggestions="[
                          { data: form.dm_asal_kapal.dm_asal_kapals }
                        ]"
                        :get-suggestion-value="getSuggestionValueTKTB"
                        :input-props="{
                          id: 'autosuggest__input',
                          class: 'form-control',
                          placeholder: 'Tujuan Kapal'
                        }"
                        @focus="
                          getSuggestionValueFocus(
                            $event,
                            'schedule',
                            'tujuan_kapal_tb'
                          )
                        "
                        @input="
                          searchForm({
                            keyword: $event,
                            uri: 'dm_asal_kapal/',
                            model: 'dm_asal_kapal',
                            currentSuggest: 'asal',
                            flagTujuanOps: 'tujuan_kapal_tb_is_new'
                          })
                        "
                      >
                        <template slot-scope="{ suggestion }">
                          <div class="d-flex align-items-center">
                            <b-avatar
                              :text="
                                Array.from(suggestion.item.asal)[0] +
                                Array.from(suggestion.item.asal)[1]
                              "
                              variant="primary"
                            />
                            <div class="detail ml-50">
                              <b-card-text class="mb-0">
                                <strong
                                  ><span v-text="`${suggestion.item.asal}`"
                                /></strong>
                                <br />
                                <small class="text-muted">
                                  <strong
                                    ><span
                                      v-text="
                                        `Created AT ${formatDateTime(
                                          suggestion.item.created_at
                                        )}`
                                      "
                                  /></strong>
                                </small>
                                <br />
                              </b-card-text>
                            </div>
                          </div>
                        </template>
                        <template slot="before-suggestions">
                          <div class="ml-1 mt-1 mb-1 mr-1">
                            <b-badge
                              href="#"
                              pill
                              :variant="
                                statusSuggestionColor[dm_asal_kapalState]
                              "
                              class="mr-1"
                            >
                              <span
                                >
                                {{
                                  statusSuggestionChoose[dm_asal_kapalState]
                                }}</span
                              >
                            </b-badge>
                            <b-badge variant="info">
                              Total:
                              {{ form.dm_asal_kapal.dm_asal_kapals.length }}
                            </b-badge>
                          </div>
                        </template>
                      </vue-autosuggest>

                      <small class="text-danger">{{ errors[0] }}</small>
                      <small
                        v-if="
                          errorPath &&
                          errorMatchValue === form.dm_asal_kapal.asal
                        "
                        class="text-danger"
                        >{{ errorPath === 'asal' ? errorValue : '' }}</small
                      >
                    </validation-provider>
                    <!-- <b-input-group-append is-text> -->
                    <b-spinner
                      v-if="dm_asal_kapalStateLoading"
                      class="suggest-loading"
                      small
                      variant="primary"
                    />
                  </div>
                </b-form-group>
              </b-col>
              <b-col
                v-if="isExistDetailID"
                :md="
                  ($store.state.app.roleName !== 'viewer' &&
                    permissionSalOnly()) ||
                  $store.state.app.roleName === 'jetty man'
                    ? '4'
                    : '3'
                "
                cols="6"
              >
                <b-form-group
                  label="Waktu Kapal Berangkat"
                  label-for="vi-tug_boat-tug_sandar_end_time"
                >
                  <!-- regex: /^[0-9]+$/ -->
                  <validation-provider
                    #default="{ errors }"
                    name="Waktu Kapal Berangkat"
                    vid="Waktu Kapal Berangkat"
                    :rules="{
                      required: false
                    }"
                  >
                    <flat-pickr
                      v-if="
                        permissionSalOnly() ||
                        $store.state.app.roleName === 'jetty man' ||
                        $store.state.app.roleName === 'security'
                      "
                      v-model="form.tug_boat.tug_sandar_end_time"
                      class="form-control"
                      static="true"
                      placeholder="WAKTU KAPAL BERANGKAT"
                      :state="errors.length > 0 ? false : null"
                      @on-change="onEndChange"
                      :config="configs.end"
                    />
                    <b-form-input
                      v-else
                      id="vi-tug_boat-tug_sandar_end_time"
                      :value="formatDateTime(form.tug_boat.tug_sandar_end_time)"
                      :state="errors.length > 0 ? false : null"
                      :readonly="permissionSalOnly()"
                      placeholder="Diisi Admin SAL | Jetty Man"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>
              <b-col
                class="text-right"
                md="2"
                cols="12"
                v-if="$store.state.app.roleName !== 'viewer'"
              >
                <b-button
                  v-if="
                    isExistDetailID || $store.state.app.roleName === 'jetty man'
                  "
                  variant="gradient-primary"
                  class="mt-2"
                  block
                  :disabled="loadingRelRKA2"
                  @click="saveRKA222()"
                >
                  <feather-icon
                    icon="CheckCircleIcon"
                    :style="{ cursor: 'pointer' }"
                    class="mr-50"
                    v-if="!loadingRelRKA2"
                  />
                  <b-spinner v-if="loadingRelRKA2" small></b-spinner>
                  <span class="align-middle">Simpan Realisasi</span>
                </b-button>
              </b-col>
            </b-row>

            <div class="divider my-2">
              <div class="divider-text">
                <h3>Realisasi Posisi Jetty</h3>
              </div>
            </div>

            <!-- REALISASI POSISI JETTY -->
            <b-alert variant="primary" show class="mb-0">
              <div class="alert-body">
                Histori Kapal Awal Sandar / Pindah Jetty
                <strong>Diisi Admin SAL / Jetty Man</strong>
              </div>
            </b-alert>

            <br />
            <NestedIO
              uri="realisasi_posisi_kapal"
              :key="form.table_jetty_position"
              :model-id="dataID"
              model="form_tug_boat"
              :posisi="jetty_positions"
              :posisi-baris="jetty_baris_positions"
              :disable-action="!!this.form.tug_boat.tug_sandar_start_time"
              :sandar-time="form.tug_boat.tug_sandar_start_time"
              :payload="{
                model_id: null,
                model: null,
                is_first: null,
                posisi_awal: null,
                posisi_awal_baris: null,
                posisi_skrg: null,
                posisi_skrg_baris: null,
                reason: null,
                waktu_pindah: null
              }"
              :column-repeat="[
                {
                  fe_id: 1
                }
              ]"
              :d-t-o="[]"
              :resolve-fetch="
                (response) => response.data.realisasi_posisi_kapals
              "
              buttonOkTitle=" Simpan Realisasi"
              @sentNotification="sentNotification"
              @alreadyRealisasi="alreadyRealisasi"
              @alreadyFetch="alreadyFetch"
            />
            <div class="mb-1" />
          </validation-observer>

          <div class="mb-1" />
        </tab-content>

        <!-- [new-1] -->
        <!-- Admin SAL -->
        <!-- v-if="isExistDetailID && form.schedule.kegiatan !== 'BUNKER'" -->
        <!-- v-if="form.schedule.kegiatan !== 'BUNKER'" -->
        <tab-content
          v-if="isExistDetailID && form.schedule.kegiatan !== 'BUNKER'"
          :title="wizard.second.step3.title"
          :icon="wizard.second.step3.icon"
        >
          <b-row>
            <b-col cols="12" class="mb-2">
              <h5 class="mb-0">{{ wizard.second.step3.content.title }}</h5>
              <small class="text-muted">
                <strong>{{ wizard.second.step3.content.subtitle }}.</strong>
              </small>
            </b-col>
          </b-row>

          <span>
            <validation-observer ref="refSAL" tag="form">
              <b-row>
                <b-col md="12" cols="12">
                  <b-card>
                    <h4 class="mb-0">{{ phrase.title_persetujuan }}</h4>
                    <hr />
                    <b-row>
                      <!-- from barge -->
                      <b-col md="4" cols="12">
                        <b-form-group
                          label="[1] Status Kapal Sesuai INAPORTNET *"
                          label-for="vi-schedule-status_kapal_tb"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="[1] Status Kapal Sesuai INAPORTNET *"
                            vid="[1] Status Kapal Sesuai INAPORTNET *"
                            rules="required"
                          >
                            <vue-autosuggest
                              id="vi-schedule-status_kapal_tb"
                              v-model="form.schedule.status_kapal_tb"
                              v-uppercase
                              :suggestions="[{ data: status_kapals }]"
                              :get-suggestion-value="getSuggestionValueManualTB"
                              :state="errors.length > 0 ? false : null"
                              :input-props="{
                                id: 'autosuggest__input',
                                class: 'form-control',
                                placeholder: `Pilih / Ketik Manual`,
                                disabled: false,
                              }"
                              @input="getSuggestionValueManualInputTB($event)"
                            >
                              <!-- :input-props="{
                                id: 'autosuggest__input',
                                class: 'form-control',
                                placeholder: `Pilih / Ketik Manual`,
                                disabled: permissionTenantAndSAL()
                              }" -->
                              <template slot-scope="{ suggestion }">
                                <div class="d-flex align-items-center">
                                  <div class="detail ml-50">
                                    <b-card-text class="mb-0">
                                      <strong
                                        ><span>
                                          {{
                                            suggestion.item.status_kapals
                                          }}</span
                                        ></strong
                                      >
                                    </b-card-text>
                                  </div>
                                </div>
                              </template>
                            </vue-autosuggest>
                            <small class="text-danger">{{ errors[0] }} </small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col md="4" cols="6">
                        <b-form-group
                          label="[2] No Pindah"
                          label-for="vi-schedule-no_pindah_tb"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="No Pindah"
                            vid="No Pindah"
                            :rules="{
                              required: false
                            }"
                          >
                            <b-form-input
                              id="vi-schedule-no_pindah_tb"
                              v-model="form.schedule.no_pindah_tb"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="'Nomor Pindah (opsional)'"
                            />

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>

                      <b-col
                        v-if="
                          $can('manage', 'all') ||
                          $can('SAL', '') ||
                          $can('TBBM JETTY 3A', '') ||
                          $router.currentRoute.params.id
                        "
                        md="4"
                        cols="6"
                      >
                        <b-form-group
                          label="[3] Nomor RPKRO *"
                          label-for="vi-schedule-no_rpkro"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Nomor RPKRO *"
                            vid="Nomor RPKRO *"
                            :rules="{
                              required: false
                            }"
                          >
                            <b-form-input
                              id="vi-schedule-no_rpkro"
                              v-model="form.schedule.no_rpkro_tb"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="'Ex: IDSRI-SALPAL-01153'"
                              />
                              <!-- :readonly="(!$can('manage', 'all') && $can('manage', 'special')) || !$can('SAL', '')" -->

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                      <b-col
                        v-if="
                          ($can('manage', 'all') ||
                          $can('SAL', '') ||
                          $router.currentRoute.params.id) &&
                          form.tug_boat.user?.abilities[0]?.action == 'TBBM JETTY 3A'
                        "
                        md="12"
                        cols="12"
                      >
                        <b-form-group
                          label="[4] Nomor PPK *"
                          label-for="vi-schedule-no_ppk_tb"
                        >
                          <validation-provider
                            #default="{ errors }"
                            name="Nomor PPK *"
                            vid="Nomor PPK *"
                            :rules="{
                              required: false
                            }"
                          >
                            <b-form-input
                              id="vi-schedule-no_ppk_tb"
                              v-model="form.schedule.no_ppk_tb"
                              :state="errors.length > 0 ? false : null"
                              :placeholder="''"
                            />
                              <!-- :readonly="
                                (!$can('manage', 'all') &&
                                  $can('manage', 'special')) ||
                                !$can('TBBM JETTY 3A', '') ||
                                !$can('SAL', '')
                              " -->

                            <small class="text-danger">{{ errors[0] }}</small>
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                  </b-card>
                </b-col>
                <!-- $can('manage', 'all') ||
                $can('manage', 'special') ||
                $can('SAL', '') ||
                $router.currentRoute.params.id -->
                <b-col
                  v-if="
                    form.schedule.jenis_kapal &&
                    (form.schedule.kegiatan === 'BUNKER' ||
                      form.schedule.kegiatan_tambahan === 'BUNKER')
                  "
                  md="6"
                  cols="6"
                >
                  <b-form-group
                    label="Waktu Mulai Berkegiatan"
                    label-for="vi-schedule-kegiatan_start_time_tb"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Waktu Mulai Berkegiatan"
                      vid="Waktu Mulai Berkegiatan"
                      :rules="{
                        required_if: 'Waktu Selesai Berkegiatan'
                      }"
                    >
                      <flat-pickr
                        v-if="$can('manage', 'all') || $can('SAL', '') || $can('TBBM JETTY 3A', '')"
                        v-model="form.schedule.kegiatan_start_time_tb"
                        class="form-control"
                        static="true"
                        placeholder="-"
                        :state="errors.length > 0 ? false : null"
                        :config="
                          $router.currentRoute.params.id !== undefined
                            ? dpconfigRevisi
                            : dpconfig
                        "
                        @on-change="onStartChange"
                      />
                      <b-form-input
                        v-else
                        id="vi-schedule-kegiatan_start_time_tb"
                        :value="
                          formatDateTime(form.schedule.kegiatan_start_time_tb)
                        "
                        :state="errors.length > 0 ? false : null"
                        :readonly="
                          (!$can('manage', 'all') &&
                            $can('manage', 'special')) ||
                          !$can('SAL', '')
                        "
                        placeholder="-"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <!-- $can('manage', 'all') ||
                    $can('manage', 'special') ||
                    $can('SAL', '') ||
                    $router.currentRoute.params.id -->
                <b-col
                  v-if="
                    form.schedule.jenis_kapal &&
                    (form.schedule.kegiatan === 'BUNKER' ||
                      form.schedule.kegiatan_tambahan === 'BUNKER')
                  "
                  md="6"
                  cols="6"
                >
                  <b-form-group
                    label="Waktu Selesai Berkegiatan"
                    label-for="vi-schedule-kegiatan_end_time_tb"
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Waktu Selesai Berkegiatan"
                      vid="Waktu Selesai Berkegiatan"
                      :rules="{
                        required_if: 'Waktu Berangkat'
                      }"
                    >
                      <flat-pickr
                        v-if="$can('manage', 'all') || $can('SAL', '') || $can('TBBM JETTY 3A', '')"
                        v-model="form.schedule.kegiatan_end_time_tb"
                        class="form-control"
                        static="true"
                        placeholder="-"
                        :state="errors.length > 0 ? false : null"
                        :config="
                          $router.currentRoute.params.id !== undefined
                            ? dpconfigRevisi
                            : dpconfig
                        "
                        @on-change="onStartChange"
                      />
                      <b-form-input
                        v-else
                        id="vi-schedule-kegiatan_end_time_tb"
                        :value="
                          formatDateTime(form.schedule.kegiatan_end_time_tb)
                        "
                        :state="errors.length > 0 ? false : null"
                        :readonly="
                          (!$can('manage', 'all') &&
                            $can('manage', 'special')) ||
                          !$can('SAL', '')
                        "
                        placeholder="-"
                      />

                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col
                  class="text-right"
                  md="12"
                  cols="12"
                  v-if="$store.state.app.roleName !== 'viewer'"
                >
                  <b-button
                    v-if="isExistDetailID && (permissionSalOnly() || $can('TBBM JETTY 3A', ''))"
                    variant="gradient-primary"
                    class="mt-2"
                    block
                    :disabled="loadingRealisasiKegiatan"
                    @click="saveRealisasiKegiatan('')"
                  >
                    <feather-icon
                      v-if="!loadingRealisasiKegiatan"
                      icon="CheckCircleIcon"
                      :style="{ cursor: 'pointer' }"
                      class="mr-50"
                    />
                    <b-spinner
                      v-if="loadingRealisasiKegiatan"
                      small
                    ></b-spinner>
                    <span class="align-middle">Simpan Realisasi Kegiatan</span>
                    <!-- Admin SAL -->
                  </b-button>
                </b-col>
              </b-row>
            </validation-observer>
          </span>

          <div class="mb-1" />
        </tab-content>
      </form-wizard>
    </div>

    <div
      class="text-center"
      v-if="$router.currentRoute.params.id !== undefined"
    >
      <b-button
        variant="success"
        :to="{
          name: 'dashboard-monitoring-rrv-tug-boat-summary-j3a',
          params: {
            id: form.form_tug_boat_id,
            api: 'form_tug_boat',
            previous_link: $router.currentRoute
          }
        }"
      >
        <feather-icon icon="EyeIcon" />
        Summary
      </b-button>
      <hr />
      <b-badge href="#" pill class="mr-1 mb-1 text-center" variant="secondary">
        <strong
          ><span v-text="`Form Perencanaan Created BY ${form.created_by}`"
        /></strong>
        <br />
      </b-badge>
      <br />
      Created AT {{ formatDateTime(form.schedule.created_at) }} - Last Updated
      AT {{ formatDateTime(form.schedule.updated_at) }}
    </div>
  </div>
</template>

<script>
import NestedIO from '@views/pages/components/NestedIO.vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import Ripple from 'vue-ripple-directive'
import BCardActions from '@core/components/b-card-actions/BCardActions.vue'
import {
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BTabs,
  BTab,
  BCardText
} from 'bootstrap-vue'
import { FormWizard, TabContent } from 'vue-form-wizard'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import OverlayLoading from '@views/components/OverlayLoading.vue'
import moment from 'moment'
import flatPickr from 'vue-flatpickr-component'
import VSelect from 'vue-select'
import router from '@/router'
import useJwt from '@/auth/jwt/useJwt'
import DropZoneUpload from '@views/components/input/upload/DropZoneUpload.vue'

import { VueAutosuggest } from 'vue-autosuggest'
import { heightTransition } from '@core/mixins/ui/transition'
import { Indonesian } from 'flatpickr/dist/l10n/id.js'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { v4 as uuidv4 } from 'uuid'

import Cleave from 'vue-cleave-component'
import VDropzone from 'vue2-dropzone'

import '@core/scss/vue/libs/vue-autosuggest.scss'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'
import ExpirationAlert from '@/views/pages/monitoring/jalur-sungai/tbbm-j3a/wizard/ExpirationAlert.vue'

export default {
  components: {
    ExpirationAlert,
    NestedIO,
    FormWizard,
    TabContent,
    BCardActions,
    DropZoneUpload,
    VDropzone,
    BCardText,
    BTabs,
    BTab,
    AppTimeline,
    AppTimelineItem,
    VSelect,
    ValidationProvider,
    ValidationObserver,
    Indonesian,
    flatPickr,
    VueAutosuggest,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContent,
    Cleave
  },
  directives: {
    Ripple
  },
  mixins: [heightTransition],
  data() {
    return {
      dpExpIjinBunker: {
        // default
        wrap: true,
        altInput: true,
        enableTime: false,
        time_24hr: true,
        dateFormat: 'Y-m-d',
        altFormat: 'j F Y',
        locale: Indonesian,
        minDate: new Date(),
        maxDate: null
        /* defaultDate: null */
      },
      dpExpIjinBunkerID: {
        wrap: true,
        altInput: true,
        enableTime: false,
        time_24hr: true,
        dateFormat: 'Y-m-d',
        altFormat: 'j F Y',
        locale: Indonesian,
        minDate: null,
        maxDate: null
        /* defaultDate: null */
      },
      dpWktBunker: {
        // default
        wrap: true,
        altInput: true,
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
        altFormat: 'j F Y - H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        /* defaultDate: null */
        end: {
          wrap: true,
          altInput: true,
          enableTime: true,
          time_24hr: true,
          dateFormat: 'Y-m-d H:i',
          altFormat: 'j F Y - H:i',
          locale: Indonesian,
          minDate: null
        }
      },
      dpWktBunkerID: {
        wrap: true,
        altInput: true,
        enableTime: true,
        time_24hr: true,
        dateFormat: 'Y-m-d H:i',
        altFormat: 'j F Y - H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null
        /* defaultDate: null */
      },
      phrase: {
        belum_sandar: "Belum memiliki Nomor RPKRO untuk sandar pada JETTY 3A",
        title_persetujuan: "Form Persetujuan Sandar",
      },
      user_id: null,
      kegiatan1key: 1,
      kegiatan2key: 2,
      no_ijin_bunker_file: [],
      id_bunker_storage: null,
      no_spog_file_tb: [],
      loadingTab1: false,
      lastStep: false,
      wizardKey: 1,
      status_kapals: [
        {
          status_kapals: 'PT. SAL'
        },
        {
          status_kapals: 'ZONA 3A'
        },
        {
          status_kapals: 'ZONA 2'
        }
      ],
      wizard: {
        first: {
          step1: {
            title: 'Main Boat',
            icon: 'feather icon-anchor',
            content: {
              title: 'Main Boat',
              subtitle: 'Data Master Kapal'
            }
          },
          step2: {
            title: 'Tug Boat',
            icon: 'feather icon-anchor',
            content: {
              title: 'Tug Boat',
              subtitle: 'Menarik Main Boat'
            }
          },
          step3: {
            title: 'Schedule',
            icon: 'feather icon-calendar',
            content: {
              title: 'Schedule',
              subtitle: 'Schedule Kapal Rencana Sandar'
            }
          },
          step4: {
            title: 'Upload File',
            icon: 'feather icon-hard-drive',
            content: {
              title: 'Upload File ( RKBM*, PBMBB )',
              subtitle: 'max size 10 MB ( xls, xlsx, pdf , jpeg , jpg , png )'
            }
          },
          step5: {
            title: 'Admin Agent',
            icon: 'feather icon-clock',
            content: {
              title: 'Admin Agent',
              subtitle:
                'Kegiatan & Realisasi, jika tidak mengisi akan di anggap tambat, ketika data terisi dianggap sesuai rencana berkegiatan'
            }
          }
        },
        second: {
          step1: {
            title: 'Jetty Man_',
            icon: 'feather icon-map-pin',
            content: {
              title: 'Jetty Man',
              subtitle:
                'Data yang diisi oleh jetty man melalui mobile apps ( android ) '
            }
          },
          step2: {
            title: 'Approvement',
            icon: 'feather icon-check-square',
            content: {
              title: 'Approvement Admin Agent',
              subtitle: 'Document Approvement'
            }
          },
          step3: {
            title: 'Realisasi',
            icon: 'feather icon-unlock',
            content: {
              title: 'Realisasi',
              subtitle: 'Document Realisasi'
            }
          }
        }
      },
      tempCheckRPKRO: null,
      tempCheckRPKRO_tb: null,
      tempCheckSPOG_tb: null,
      tempCheckPindah: null,
      tempCheckAlrSandar: null,
      tempCheckAlrSandarBrs: null,
      files: [],
      filesCheck: null,
      BACKEND_URL: `${process.env.VUE_APP_BACKEND_URL}`,
      isHaveFile: [],
      isExistDetailID: !!this.$router.currentRoute.params.id /* Boolean */,
      dataID:
        this.$router.currentRoute.params
          .id /* Integer */ /* this a form hulu migas id */,
      detail_form_tug_boat_id:
        this.$router.currentRoute.params
          .detail_form_tug_boat_id /* Integer */ /* this a form tug boat id */,

      /* loading */
      inProgressTerbitkanForm: false,
      loadingSaveTenant: false /* done */,
      loadingDupRevTB: false,
      loadingChange: false,
      loadingFileStorage: false,
      loadingRelRKA1: false /* done */,
      loadingRelRKA2: false /* done */,
      loadingRealisasiKegiatan: false /* done */,
      loadingRealisasiKegiatanSec: false /* done */,
      loadingTemPatra: false /* done */,
      loadingRealisasiCrew: false /* done */,

      errorPath: null,
      errorCatch: null,
      errorMatchValue: null,
      errorValue: null,
      formRender: 1,

      mapStatePayloadDMK: {
        UPDATE_WITH_NEW_DATA: 'UPDATE_WITH_NEW_DATA' /* duplicate */,
        UPDATE_ON_EXIST_DATA: 'UPDATE_ON_EXIST_DATA' /* revisi */,
        CHANGE_ON_EXIST_DATA: 'CHANGE_ON_EXIST_DATA' /* change */
      },
      mapMsgPayloadDMK: {
        UPDATE_WITH_NEW_DATA:
          'Duplicate data and Create as a new data' /* duplicate */,
        UPDATE_ON_EXIST_DATA: 'Revisi data kapal' /* revisi */,
        CHANGE_ON_EXIST_DATA: 'Berhasil mengubah/mengganti kapal'
      },

      statePayloadDMKTBMaster: null /* TUG BOAT */,
      statePayloadDMKTB: null /* TUG BOAT */,

      stateInputDMKTBMaster: true /* TUG BOAT */,
      stateInputDMKTB: true /* TUG BOAT */,

      kegiatan: [],
      pemilik: [],
      jetty_positions: [],
      formTitle: '',
      domain: process.env.VUE_APP_URL,
      userData: JSON.parse(localStorage.getItem('userData')),
      groupName: JSON.parse(localStorage.getItem('userData')).group?.name ?? '',
      dpconfig: {
        // default
        wrap: true,
        enableTime: true,
        time_24hr: true,
        altInput: true,
        altFormat: 'j F Y - H:i',
        dateFormat: 'Y-m-d H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        defaultDate: null
      },
      dpconfigRevisi: {
        wrap: true,
        enableTime: true,
        time_24hr: true,
        altInput: true,
        altFormat: 'j F Y - H:i',
        dateFormat: 'Y-m-d H:i',
        locale: Indonesian,
        minDate: null,
        maxDate: null,
        defaultDate: null
      },
      configs: {
        end: {
          wrap: true,
          altInput: true,
          enableTime: true,
          time_24hr: true,
          dateFormat: 'Y-m-d H:i',
          locale: Indonesian,
          minDate: null
        }
      },
      jetty_baris_positions: [
        {
          value: 'null',
          text: 'Posisi Baris'
        },
        {
          value: 'Baris 1',
          text: 'Baris 1'
        },
        {
          value: 'Baris 2',
          text: 'Baris 2'
        },
        {
          value: 'Baris 3',
          text: 'Baris 3'
        }
      ],
      jenis_kapals: [
        {
          value: 'null',
          text: 'Pilih Jenis Kapal'
        },
        {
          value: 'LCT / KM / MT / SPOB / Sea Truck',
          text: 'LCT / KM / MT / SPOB / Sea Truck'
        },
        {
          value: 'Barge',
          text: 'Barge'
        },
        {
          value: 'Tug Boat',
          text: 'Tug Boat'
        }
      ],
      options: {
        creditCard: {
          creditCard: true
        },
        date: {
          date: true,
          delimiter: '-',
          datePattern: ['Y', 'm', 'd']
        },
        phone: {
          phone: true,
          phoneRegionCode: 'US'
        },
        time: {
          time: true,
          timePattern: ['h', 'm', 's']
        },
        number: {
          // numeral: true,
          // numeralThousandsGroupStyle: 'thousand',
          numeral: true,
          numeralDecimalMark: ',',
          delimiter: '.'
          // suffix: 'LTR',
        },
        no_pol: {
          numeral: true,
          blocks: [4, 3, 3],
          uppercase: true
        },
        block: {
          blocks: [4, 3, 3],
          uppercase: true
        },
        delimiter: {
          delimiter: '·',
          blocks: [3, 3, 3],
          uppercase: true
        },
        customDelimiter: {
          delimiters: ['.', '.', '-'],
          blocks: [3, 3, 3, 2],
          uppercase: true
        },
        prefix: {
          phone: true,
          phoneRegionCode: 'ID'
        }
      },
      payload: [],
      form_realisasi: {
        rka1: null,
        rka2: null
      },
      form: {
        realisasi_posisi_kapals: [],
        verification_hm: [],
        flag_verified: null,
        /* for form chain tbbm */
        form_chain_tbbm_id: null,

        form_tug_boat_id: null,
        form_tbbm_id: null,

        uri_chain_master: 'form_chain_tbbm',
        uri_chain_main:
          '' /* already override by form tbbm  in schedule payload */,
        uri_chain_second: 'form_tug_boat',
        /* for form chain hm */

        currentModel: '',
        currentFieldSuggest: '',
        currentFieldSuggestChild: null,
        currentFieldHaveChildren: null,

        isSuccessTugBoat: false,
        isTugBoatOnly: false,
        // isAgenAndTrans: false,
        // showIsAgenAndTrans: false,
        // temp_transportir_id: '',

        group: '',
        pemilik: '',
        created_by: '',
        dm_river_boat: {
          updateID: null,
          isEverRun: false,
          uri: 'dm_river_boat/',
          isNew: true,
          boat_name: '',
          gt: null,
          loa: null,
          captain_name: '',
          captain_telp: '',
          // created_at: moment(),
          dm_river_boats: [],
          dm_shipping_agent_id: null,
          dm_shipping_agent: {
            updateID: null,
            isEverRun: false,
            uri: 'dm_shipping_agent/',
            isNew: true,
            shipping_name: '',
            dm_shipping_agents: []
          }
        },
        dm_shipping_agent: {
          updateID: null,
          isEverRun: false,
          uri: 'dm_shipping_agent/',
          isNew: true,
          shipping_name: '',
          dm_shipping_agents: []
        },
        // dm_company_bongm: {
        //   updateID: null,
        //   isEverRun: false,
        //   uri: 'dm_company_bongm/',
        //   isNew: true,
        //   company_bongm_name: '',
        //   dm_company_bongms: []
        // },
        dm_asal_kapal: {
          updateID: null,
          isEverRun: false,
          uri: 'dm_asal_kapal/',
          isNew: true,
          asal: '',
          dm_asal_kapals: []
        },
        tug_boat: {
          updateID: null,
          isEverRun: false,
          uri: 'tug_boat/',
          isNew: true,
          no_pkk_tug_boat: '',
          name_tug_boat: '',
          tug_sandar_start_time: null,
          tug_sandar_end_time: null,
          tug_boats: [],
          gt_tug_boat: null,
          loa_tug_boat: null,
          captain_name_tug_boat: '',
          captain_telp_tug_boat: '',
          // no_pkk: '',
          // created_at: moment(),
          dm_shipping_agent_id: null,
          dm_shipping_agent: {
            updateID: null,
            isEverRun: false,
            uri: 'dm_shipping_agent/',
            isNew: true,
            shipping_name: '',
            dm_shipping_agents: []
          }
        },
        schedule: {
          count_crew_tb: null, // form tug boat
          exp_ijin_bunker: null,
          no_ijin_bunker: null,
          total_bunker: null,
          no_pindah_tb: null,
          // api
          kegiatan: null,
          kegiatan_tambahan: null,
          status_kapal_tb: null,
          asal: null,
          asal_is_new: false,
          tujuan_kapal_tb: null,
          tujuan_kapal_tb_is_new: false,
          uri: 'form_tug_boat/',
          uriAgent: 'form_tug_boat/update/persetujuan/',
          uriStatus: 'form_tug_boat/update/status/',
          status_kapal: '',
          // payload
          // kegiatan: '',
          jenis_kapal: null,
          tug_boat_id: null,

          rencana_sandar: null,
          no_do_bl: '',
          no_pkk: '',
          no_spog_tb: null,
          pemilik: '',
          uuid: uuidv4(),
          position_jetty: null,
          position_baris_jetty: null
        }
      },
      dataOverview: [],
      agenOverview: [],
      transportirOverview: [],
      landVehicleOverview: [],
      // deliveryRoutesOverview: [],
      fetchLoading: false,
      selectedContry: 'select_value',
      selectedLanguage: 'nothing_selected',
      items: [
        {
          id: 1,
          selected: 'male',
          selected1: 'designer',
          prevHeight: 0
        }
      ],
      nextTodoId: 2,
      payloadNotification: {},

      // state suggestion
      dm_river_boatState: 0,
      dm_shipping_agentState: 0,
      tug_boatState: 0,
      // dm_company_bongmState: 0,
      dm_asal_kapalState: 0,

      statusSuggestion: {
        0: 'Membuat Bank Data Baru',
        1: 'Menggunakan Bank Data',
        2: 'Menggunakan Bank Data & Membuat Bank Data Baru'
      },
      statusSuggestionColor: {
        0: 'primary',
        1: 'warning',
        2: 'success'
      },
      statusSuggestionChoose: {
        0: 'Pilih Data yang pernah dibuat [ reuse-data ]',
        1: 'cancel [ reuse-data ] by click "space" then "backspace"',
        2: 'Pilih Data yang pernah dibuat [ reuse-data ]'
      },
      // state loading
      dm_river_boatStateLoading: false,
      dm_shipping_agentStateLoading: false,
      tug_boatStateLoading: false,
      // dm_company_bongmStateLoading: false,
      dm_asal_kapalStateLoading: false
    }
  },
  computed: {
    disableActionButton(){
      /*
       * Disable ketika ijin bunker expired dan belum sandar
       *  */
      return () => {
        const isHaveIjinBunker = this.id_bunker_storage;
        const isMainKegiatanBunker = this.form.schedule.kegiatan === 'BUNKER';
        const isAddMainKegiatanBunker = this.form.schedule.kegiatan_tambahan === 'BUNKER';

        console.log('isHaveIjinBunker', isHaveIjinBunker);
        console.log('isMainKegiatanBunker', isMainKegiatanBunker);
        console.log('isAddMainKegiatanBunker', isAddMainKegiatanBunker);

        if(isHaveIjinBunker && (isMainKegiatanBunker || isAddMainKegiatanBunker)){
          /* sudah memiliki data ijin bunker */
          const expiredIjinBunker = this.form.schedule.exp_ijin_bunker;
          const isRencanaSandar = this.form.schedule.form_status === "KAPAL RENCANA SANDAR";

          if(isRencanaSandar && expiredIjinBunker){
            /* kapal masih rencana sandar */
            console.log('kapal masih rencana sandar', expiredIjinBunker);

            // const today = moment().startOf('day'); // Ensure only the date is compared, not the time
            // const expiration = moment(expiredIjinBunker).startOf('day');

            // return today.isSameOrAfter(expiration);

            const today = moment(); // Current date and time
            const expiration = moment(expiredIjinBunker); // Expiration date and time
            console.log('expiration:: ', expiration);

            // Compare if current time is after the expiration time, considering time as well
            return today.isAfter(expiration);
          } else {
            /* sudah melewati status "kapal rencana sandar" */
            console.log('sudah melewati status "kapal rencana sandar"');
            return false;
          }
        } else {
          /* belum create data tab ijin bunker */
          console.log('belum create data tab ijin bunker');
          return false
        }
      }
    },
    readonlyFieldTabAdminSAL() {
      /* hanya admin sal */
      return () => this.$can('manage', 'all') || this.$can('TBBM JETTY 3A', '') || this.$can('SAL', '')
    },
    editAble() {
      return (any) =>
        this.$can('TBBM JETTY 3A', '')
          ? true
          : this.$can('SAL', '') || any === this.userData.id
    },
    isLastStep() {
      return () => {
        return this.lastStep
      }
    },
    permissionCreateTB() {
      return (any) => {
        return (
          this.form.schedule.jenis_kapal === 'Tug Boat' ||
          this.form.schedule.jenis_kapal === 'Barge'
        )
      }
    },
    permissionCreate() {
      return (any) => {
        return (
          this.form.schedule.jenis_kapal &&
          this.form.schedule.jenis_kapal !== 'Tug Boat'
        )
      }
    },
    formName() {
      const isTBBM = this.form.tug_boat.user?.abilities.some(ability => ability.action === 'TBBM JETTY 3A')
      const isHuluMigas = this.form.tug_boat.user?.abilities.some(ability => ability.action === 'Hulu Migas')
      let name = "";
      if(isTBBM){
        name = "TBBM JETTY 3A"
      }
      if(isHuluMigas){
        name = "Hulu Migas"
      }
      return name
    },
    formStatusVariant() {
      return (any) => this.$variantDDFormStatus[any]
    },
    permissionTenant() {
      /* admin tenant => no  */
      /* tenant       => yes */
      /* sal          => yes */
      const showInput =
        !this.$can('manage', 'all') &&
        (this.$can('manage', 'special') ||
          this.$can('manage', 'qrcode') ||
          this.$can('manage', 'jetty'))
          ? true
          : this.isExistDetailID !== undefined
          ? false
          : null

      return () => showInput
    },
    permissionTenantChild() {
      const showInputChild =
        !this.$can('manage', 'all') &&
        (this.$can('manage', 'special') ||
          this.$can('manage', 'qrcode') ||
          this.$can('manage', 'jetty'))
          ? true
          : this.isExistDetailID !== undefined
          ? false
          : !this.form.dm_river_boat.isNew
      return () => showInputChild
    },
    permissionAdminTenant() {
      /* admin tenant => yes  */
      /* tenant       => no */
      /* sal          => yes */

      // !$can('manage', 'all') && $can('manage', 'special')
      const hostCanModified = this.$can('manage', 'all')
      const adminSAL = this.$can('SAL', '')
      const adminTenantModified = this.$can('manage', 'special')

      const condition = this.isExistDetailID
        ? /* on details data */ hostCanModified ||
          adminSAL ||
          adminTenantModified
        : /* on create | skip */ false
      // if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)

      // return () => (this.timeoutDebounce = setTimeout(() => condition, 300))
      return () => condition
    },
    permissionSalOnly() {
      /* hanya admin sal */
      const hostCanModified = this.$can('manage', 'all')
      const salCanModified = this.$can('SAL', '')

      const condition = this.isExistDetailID
        ? /* on details data */ hostCanModified || salCanModified
        : /* on create | skip */ false
      return () => condition
    },
    permissionTenantAndSAL() {
      /* disable is for I/O else VIEW */
      const condition = this.isExistDetailID
        ? /* on details data */
          this.$can('manage', 'all') ||
          this.$can('TBBM JETTY 3A', '') ||
          this.$can('Hulu Migas', '') ||
          this.$can('SAL', '')
        : /* on create | skip */ null
      return () => (this.isExistDetailID ? !condition : condition)
    },
    permissionSuggestionTugBoatMain() {
      return () =>
        this.isExistDetailID
          ? this.stateInputDMKTBMaster
          : this.permissionTenantAndSAL()
    },
    permissionSuggestionTugBoat() {
      /* on create disable when use suggestion */
      /* on details enable only for sal  */
      const hostCanModified = this.$can('manage', 'all')
      const salCanModified = this.$can('SAL', '')
      const tenantCanModified = this.$can('TBBM JETTY 3A', '')

      const isExistRiverBoat = !this.form.tug_boat.isNew

      const onCreate = isExistRiverBoat /* disable when use suggestion value */

      const condition = this.isExistDetailID
        ? /* on details data */ salCanModified ||
          tenantCanModified ||
          hostCanModified
        : /* on creating data */ onCreate
      return () => (this.isExistDetailID ? this.stateInputDMKTB : condition)
    },
    handlingTugBoat() {
      return () => this.form.tug_boat.tug_boats
    }
  },
  watch: {
    'form.schedule.jenis_kapal': function (params) {
      console.log('form.schedule.jenis_kapal::', params)
      this.configWizardFirst()
    },
    'form.schedule.kegiatan': function (params) {
      console.log('form.schedule.kegiatan::', params)
      this.kegiatan1key += 1
      this.configWizardFirst()
    },
    'form.schedule.kegiatan_tambahan': function (params) {
      console.log('form.schedule.kegiatan_tambahan::', params)
      this.kegiatan2key += 1
      this.configWizardFirst()
    },
    '$route.params': function (params) {
      this.dataID = params.id
      this.isExistDetailID = !!params.id
      this.fetchData(true)
    },
    errorCatch() {
      try {
        const [firstKey] = Object.keys(this.errorCatch.response.data.message) // by keys
        const [firstValue] = Object.values(
          this.errorCatch.response.data.message
        )
        this.errorMatchValue =
          this.errorCatch.response.data.message.value || null // by values
        this.errorPath = firstKey
        this.errorValue = firstValue
      } catch (error) {
        console.log('watch error catch error : ', error)
        console.log('watch error catch error : ', this.errorCatch)
      }
    }
  },
  mounted() {
    document.body.style.zoom = '100%'
    this.configWizardFirst()
    // this.initTrHeight()
    // const icon = this.userData.group?.name === 'PATRA' ? '📔' : '📗'
    // this.formTitle = `${icon} FORM TRUK MASUK KE ${this.userData.group?.name ?? '-'} (${this.userData.group?.description ?? ' -'} )`
    // const dataID = router.currentRoute.params.id
    // if (dataID) {
    //   const { tabs } = this.$refs.wizard
    //   tabs.forEach(tab => tab.checked = true)
    //   this.$refs.wizard.maxStep = tabs.length
    // }
  },
  destroyed() {
    // document.body.style.zoom = '100%'
  },
  async created() {
    window.addEventListener('resize', this.initTrHeight)
    if (
      this.$store.state.app.tokenNotification === '' ||
      this.$store.state.app.tokenNotification === null
    ) {
      this.$swal({
        title: 'Info, Notifikasi Bermasalah!',
        text: ' Kami telah secara otomatis mendeteksi pemberitahuan token sudah kedaluwarsa atau belum mengaktifkan izin notifikasi browser, Fix Notifikasi untuk memperbarui token dan coba kembali ke halaman ini.\njika Anda berada di browser Safari, coba lagi dengan browser Chrome/Firefox dengan perangkat Android atau Laptop/Komputer (OS windows/macOS)\n\n',
        icon: 'info',
        showCancelButton: true,
        confirmButtonText: 'Fix (kedaluwarsa)',
        cancelButtonText: 'Abaikan Fitur Ini (iOS)',
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-outline-danger ml-1'
        },
        buttonsStyling: false
      }).then(async (result) => {
        if (result.value) {
          this.$forceUpdate()
          if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
          this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
        } else {
          console.log('mengabaikan fitur notifikasi')
        }
      })
    }
    await this.fetchData()
    await this.jettyPositionTab()
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    moment,
    async saveCrewKapal() {
      const count_crew_tb = this.form.schedule.count_crew_tb

      if (count_crew_tb) {
        const _model_id = this.form.form_tug_boat_id
        const _uri = `visitor_planning_river/${_model_id}/form_tug_boat`
        await useJwt.http
          .put(`${_uri}`, { count_crew: count_crew_tb })
          .then(async () => {
            this.$alert({
              title: 'Berhasil menyimpan data crew kapal',
              variant: 'success',
              icon: 'CheckIcon'
            })
          })
          .catch((error) => {
            this.$alert({
              title: 'Tidak Berhasil menyimpan data crew kapal',
              variant: 'danger',
              icon: 'XIcon'
            })
            console.log(`error ${error}`)
          })
      }
    },
    saveFormAdminSALBerkegiatanBunker(isApprove) {
      return new Promise((resolve, reject) => {
        if (this.isBerangkatOrBatal() == true) {
          return
        }
        this.formAdminTEMPATRA(isApprove)
      })
    },
    async saveRealisasiBunker() {
      try {
        // [new-1]
        // this.saveFormAdminSALBerkegiatanBunker(
        //   // this.form.schedule.no_do_bl !== 'no_do_bl_sal' ? 'no_do_bl_sal' : 'no_do_bl'
        //   'no_do_bl_sal'
        // )
        const URI = `file_storage_bunker/admin/${this.id_bunker_storage}`
        const payload = {
          kegiatan_start_time: this.form.schedule.kegiatan_start_time_bunker,
          kegiatan_end_time: this.form.schedule.kegiatan_end_time_bunker
        }
        await useJwt.http
          .put(`${URI}`, payload)
          .then(async () => {
            // if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
            // this.timeoutDebounce = setTimeout(
            //   () => (this.loadingRelRKA1 = false),
            //   300
            // )
            this.$alert({
              title: 'Berhasil realisasi bunker',
              variant: 'success',
              icon: 'CheckIcon'
            })
          })
          .catch((err) => {
            this.$alert({
              title: 'Tidak Berhasil realisasi bunker',
              variant: 'danger',
              icon: 'XIcon'
            })
            console.log(`error ${err}`)
          })
      } catch (e) {
        console.log('an error realisasi bunker:', e)
      }
    },
    /* single */
    onStartChangedpExpIjinBunker(selectedDates, dateStr, instance, model) {
      // this.dpRencanaSandar.end.minDate = dateStr
    },

    /* Bunker */
    onStartChangedpWktBunker(selectedDates, dateStr, instance, model) {
      this.dpWktBunker.end.minDate = dateStr
    },
    onEndChangedpWktBunker(selectedDates, dateStr, instance) {
      this.dpWktBunkerID.maxDate = dateStr
    },
    isBerangkatOrBatal(){
      console.log('host', this.$can('manage', 'all'));
      console.log('host', this.$can('SAL', ''));
      const form_status = this.form.schedule.form_status
      if((form_status === 'KAPAL BERANGKAT' || form_status === 'KEGIATAN DIBATALKAN')) {
        if(this.$can('manage', 'all') || this.$can('SAL', '')){
          return false
        }
        this.alertIsBerangkatOrBatal()
        return true
      } else {
        return false
      }
    },
    alertIsBerangkatOrBatal(){
      const form_status = this.form.schedule.form_status
      this.$alert({
        title: `Maaf`,
        text: `Perubahaan pada data kapal dengan status ${form_status} tidak dapat dilakukan.`,
        variant: 'warning',
        icon: 'XCircleIcon'
      })
    },
    getSuggestionValueManualTB(suggestion) {
      this.form.schedule.status_kapal_tb = suggestion.item.status_kapals
      this.$refs.refSAL.validate()
      return suggestion.item.status_kapals
    },
    getSuggestionValueManualInputTB(keyword) {
      this.form.schedule.status_kapal_tb = keyword
    },
    async alreadyFetch(model) {
      this.form.realisasi_posisi_kapals = model
    },
    async alreadyRealisasi(event) {
      console.log('alreadyRealisasi::trigger')
      this.fetchData()
    },
    jettyPositionTab() {
      console.log('auto set to jettyman')
      if (this.$can('manage', 'jetty')) {
        this.$refs.wizardFirst.tabs[0].active = false
        this.$refs.wizardFirst.tabs.forEach((tab, index) => {
          console.log('tabid', tab)
          if (tab.tabId.includes('Jetty')) {
            this.$refs.wizardFirst.tabs[index].active = true
          }
        })
      }
    },
    copyText(string) {
      this.$copyText(string).then(
        () => {
          this.$alert({
            title: `Text copied : ${string}`,
            variant: 'primary',
            icon: 'CheckCircleIcon'
          })
        },
        (e) => {
          this.$alert({
            title: `Can not copy!`,
            variant: 'danger',
            icon: 'XCircleIcon'
          })
        }
      )
    },
    configWizardFirst() {
      this.$nextTick(() => {
        const { tabs } = this.$refs.wizardFirst
        // tabs.forEach((tab) => (tab.checked = true))
      // console.log('much tabs::', tabs)
        this.$refs.wizardFirst.tabs = tabs.sort(
          (a, b) => a.tabId.slice(-1) - b.tabId.slice(-1)
        ) // b - a for reverse sort
        // console.log(
        //   'this.$refs.wizardFirst.tabs::',
        //   this.$refs.wizardFirst.tabs
        // )
        // this.$refs.wizardFirst.maxStep = tabs.length

        this.$refs.wizardFirst.activateAll()
        // console.log('tabs.length::', tabs.length)
        if (tabs.length === 1) {
          this.$nextTick(() => {
            this.lastStep = true
          })
        }
        // this.$refs.wizardFirst.hideButtons = true
      })
    },
    onTabChange(prevTab, currentTab) {
      this.$nextTick(() => {
        try {
          if (this.$refs.wizardFirst.isLastStep === true) {
            this.$nextTick(() => {
              this.lastStep = true
            })
          } else {
            this.$nextTick(() => {
              this.lastStep = false
            })
          }
        } catch (error) {
          console.log('wizard in building')
        }
      })
    },
    async updateTab1() {
      if(this.isBerangkatOrBatal() == true) {
        return;
      }
      this.loadingTab1 = true
      const kegiatan1 = this.form.schedule.kegiatan
      const kegiatan2 = `, ${this.form.schedule.kegiatan_tambahan}`
      const join_kegiatan = `${kegiatan1}${
        this.form.schedule.kegiatan_tambahan ? kegiatan2 : ''
      }`
      const final_kegiatan = kegiatan1 === 'BUNKER' ? kegiatan1 : join_kegiatan

      const payload = {
        no_pindah_tb: this.form.tug_boat.no_pindah_tb,
        rencana_position_jetty: this.form.tug_boat.rencana_position_jetty,
        rencana_baris_position_jetty:
          this.form.tug_boat.rencana_baris_position_jetty,
        rencana_sandar: this.form.tug_boat.rencana_sandar,
        kegiatan: final_kegiatan,
        dm_asal_kapal: this.form.dm_asal_kapal
      }
      return new Promise((resolve, reject) => {
        this.$refs.tbbmRulesTugBoat.validate().then(async (success) => {
          if (success) {
            resolve(true)
            await useJwt.http
              .put(
                `form_tug_boat/${this.form.form_tug_boat_id}/tab/tb`,
                payload
              )
              .then(async () => {
                if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                this.timeoutDebounce = setTimeout(
                  () => (this.loadingTab1 = false),
                  300
                )
                this.configWizardFirst()
                this.$swal({
                  // position: 'top-end',
                  icon: 'success',
                  title: 'Berhasil menyimpan',
                  // html: 'Berhasil menyimpan',
                  showConfirmButton: false,
                  timer: 1000,
                  customClass: {
                    confirmButton: 'btn btn-primary'
                  },
                  buttonsStyling: false
                })
              })
              .catch((e) => {
                this.timeoutDebounce = setTimeout(
                  () => (this.loadingTab1 = false),
                  300
                )
              })
          } else {
            reject()
            if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
            this.timeoutDebounce = setTimeout(
              () => (this.loadingTab1 = false),
              300
            )
            this.$swal({
              // position: 'top-end',
              icon: 'warning',
              title: 'Please check mandatory field is required',
              // html: 'Berhasil menyimpan',
              showConfirmButton: false,
              timer: 1000,
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            })
          }
        })
      })
    },
    formSubmitted() {
      this.initCreateData()
    },
    async sentNotificationDiijinkanSandar() {
      console.log('sent notification, form diijinkan sandar')
      if (this.form.schedule.no_rpkro_tb) {
        if (this.tempCheckRPKRO_tb !== this.form.schedule.no_rpkro_tb) {
          console.log('sent')
          await this.sentNotification({
            action: this.$notificationMsg['setuju_sal'],
            timeIfPindah: moment(),
            positionIfPindah: `${this.form.schedule.no_rpkro_tb}`,
            screen_re_fetch: ['form_chain_tbbm']
          })
        } else {
          console.log("don't sent")
        }
      } else {
        console.log('empty')
      }
    },
    async saveRKA1() {
      this.loadingRelRKA1 = true
      return new Promise((resolve, reject) => {
        this.$refs.refRKA1.validate().then(async (success) => {
          if (success) {
            resolve(true)
            const URI = `form_tbbm/${this.form.form_tbbm_id}/jettyman/boat`
            const sandar = this.form.schedule.sandar_start_time
            const berangkat = this.form.schedule.sandar_end_time
            const payload = {
              is_cancel: this.form.schedule.is_cancel,
              position_jetty: this.form.schedule.position_jetty,
              position_baris_jetty: this.form.schedule.position_baris_jetty,
              sandar_start_time: sandar,
              sandar_end_time: berangkat
            }

            await useJwt.http
              .put(`${URI}`, payload)
              .then(async () => {
                if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                this.timeoutDebounce = setTimeout(
                  () => (this.loadingRelRKA1 = false),
                  300
                )

                this.$swal({
                  title: 'Good job!',
                  html: 'Berhasil merealisasikan kapal<br>Tap atau Klik dimana saja untuk kembali',
                  icon: 'success',
                  confirmButtonText: 'Lanjutkan Ubah Data',
                  cancelButtonText: 'Kembali',
                  showCancelButton: true,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-warning ml-1'
                  },
                  buttonsStyling: false
                }).then(async (result) => {
                  if (result.value) {
                    this.fetchData()
                  } else {
                    if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                    this.timeoutDebounce = setTimeout(
                      () => this.$router.go(-1),
                      300
                    )
                  }
                })
                if (
                  this.form.schedule.jenis_kapal !== 'Tug Boat' ||
                  this.form.schedule.jenis_kapal !== 'Barge'
                ) {
                  let state
                  console.log('!berangkat::', !berangkat)
                  let time
                  if (sandar && !berangkat) {
                    /* sandar */
                    state = this.$notificationMsg['sandar']
                    if (this.tempCheckAlrSandar && this.tempCheckAlrSandarBrs) {
                      if (
                        this.tempCheckAlrSandarBrs !==
                          this.form.schedule.position_jetty ||
                        this.tempCheckAlrSandar !==
                          this.form.schedule.position_baris_jetty
                      ) {
                        const pos = this.form.schedule.position_jetty
                        const baris = this.form.schedule.position_baris_jetty
                        await this.sentNotification({
                          action: `Pindah Jetty Dari #POSISI_JETTY_${this.tempCheckAlrSandarBrs} #${this.tempCheckAlrSandar}, `,
                          timeIfPindah: moment(),
                          positionIfPindah: `Ke #POSISI_JETTY_${pos} #${baris}`,
                          screen_re_fetch: ['form_chain_tbbm']
                        })
                        return
                      }
                    }
                    time = sandar
                  }
                  if (sandar && berangkat) {
                    state = this.$notificationMsg['berangkat']
                    time = berangkat
                  }

                  if (state) {
                    const pos = this.form.schedule.position_jetty
                    const baris = this.form.schedule.position_baris_jetty
                    await this.sentNotification({
                      action: state,
                      timeIfPindah: time,
                      positionIfPindah: `#POSISI_JETTY_${pos} #${baris}`,
                      screen_re_fetch: ['form_chain_tbbm']
                    })
                  }
                }
              })
              .catch((error) => {
                if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                this.timeoutDebounce = setTimeout(
                  () => (this.loadingRelRKA1 = false),
                  300
                )
                this.fetchLoading = false
                this.errorCatch = error
              })
            if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
            this.timeoutDebounce = setTimeout(
              () => (this.loadingRelRKA1 = false),
              300
            )
          } else {
            if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
            this.timeoutDebounce = setTimeout(
              () => (this.loadingRelRKA1 = false),
              300
            )
            reject()
          }
        })
      })
    },
    async saveRKA2() {
      this.loadingRelRKA2 = true
      return new Promise((resolve, reject) => {
        this.$refs.refRKA2.validate().then(async (success) => {
          if (success) {
            resolve(true)
            this.loadingRelRKA2 = true
            const URI = `form_tug_boat/${this.form.form_tug_boat_id}/jettyman/tugboat`
            const sandar = this.form.tug_boat.tug_sandar_start_time
            const berangkat = this.form.tug_boat.tug_sandar_end_time
            const payload = {
              ftb_only: true,
              position_jetty: this.form.schedule.position_jetty,
              position_baris_jetty: this.form.schedule.position_baris_jetty,
              sandar_start_time: sandar,
              sandar_end_time: berangkat,
              tujuan_kapal_tb: this.form.schedule.tujuan_kapal_tb,
              tujuan_kapal_tb_is_new: this.form.schedule.tujuan_kapal_tb_is_new
            }
            await useJwt.http
              .put(`${URI}`, payload)
              .then(async () => {
                this.loadingRelRKA2 = false
                this.$swal({
                  title: 'Good job!',
                  html: 'Berhasil merealisasikan kapal<br>Tap atau Klik dimana saja untuk kembali',
                  icon: 'success',
                  confirmButtonText: 'Lanjutkan Ubah Data',
                  cancelButtonText: 'Kembali',
                  showCancelButton: true,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-warning ml-1'
                  },
                  buttonsStyling: false
                }).then(async (result) => {
                  if (result.value) {
                    this.fetchData()
                  } else {
                    if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                    this.timeoutDebounce = setTimeout(
                      () => this.$router.go(-1),
                      300
                    )
                  }
                })
                let state
                let time
                if (sandar && !berangkat) {
                  /* sandar */
                  state = this.$notificationMsg['sandar']
                  time = sandar
                }
                if (sandar && berangkat) {
                  state = this.$notificationMsg['berangkat']
                  time = berangkat
                }
                let screen_re_fetch = ['form_chain_tbbm']
                if (state) {
                  const pos = this.form.schedule.position_jetty
                  const baris = this.form.schedule.position_baris_jetty

                  if (['Hulu Migas'].includes(this.form.schedule.pemilik)) {
                    screen_re_fetch = ['form_chain_hm']
                  }
                  await this.sentNotification({
                    action: state,
                    timeIfPindah: time,
                    positionIfPindah: `#POSISI_JETTY_${pos} #${baris}`,
                    screen_re_fetch: screen_re_fetch
                  })
                }
              })
              .catch((error) => {
                this.loadingRelRKA2 = false
                this.fetchLoading = false
                this.errorCatch = error
              })
            this.loadingRelRKA2 = false
          } else {
            this.loadingRelRKA2 = false
            reject()
          }
        })
      })
    },
    async saveRKA222() {
      this.loadingRelRKA2 = true
      const URI = `form_hulu_migas/${this.form.form_tug_boat_id}/jettyman/tugboat`
      const sandar = this.form.tug_boat.tug_sandar_start_time
      const berangkat = this.form.tug_boat.tug_sandar_end_time
      const payload = {
        sandar_start_time: sandar,
        sandar_end_time: berangkat,
        tujuan_kapal_tb: this.form.schedule.tujuan_kapal_tb
      }
      await useJwt.http
        .put(`${URI}`, payload)
        .then(async () => {
          this.loadingRelRKA2 = false
          this.$swal({
            title: 'Good job!',
            html: 'Berhasil merealisasikan kapal<br>Tap atau Klik dimana saja untuk kembali',
            icon: 'success',
            confirmButtonText: 'Lanjutkan Ubah Data',
            cancelButtonText: 'Kembali',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              this.fetchData()
            } else {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            }
          })
          let state
          if (sandar && berangkat) {
            state = 'Berangkat'
            const pos = this.form.realisasi_posisi_kapals
            const len = this.form.realisasi_posisi_kapals.length - 1

            await this.sentNotification({
              action: state,
              timeIfPindah: berangkat,
              positionIfPindah: `#POSISI_JETTY_${pos[len].posisi_skrg} #${pos[len].posisi_skrg_baris}`,
              screen_re_fetch: ['form_chain_hm']
            })
          }
        })
        .catch((error) => {
          this.loadingRelRKA2 = false
          this.fetchLoading = false
          this.errorCatch = error
        })
    },
    async saveIjinBunker() {
      try {
        await this.uploadSPOG({
          model: 'form_tug_boat',
          model_id: this.dataID,
          singleAppendFile: this.form.schedule.no_ijin_bunker_file,
          uri: 'file_storage_bunker',
          no_ijin_bunker: this.form.schedule.no_ijin_bunker,
          total_bunker: this.form.schedule.total_bunker,
          exp_ijin_bunker: this.form.schedule.exp_ijin_bunker
        })
          .then((success) => {
            console.log('success upload file ijin bunker')
            this.$swal({
              title: 'Good job!',
              html: 'Berhasil upload file ijin bunker<br>Tap atau Klik dimana saja untuk kembali',
              icon: 'success',
              confirmButtonText: 'Lanjutkan Ubah Data',
              cancelButtonText: 'Kembali',
              showCancelButton: true,
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-warning ml-1'
              },
              buttonsStyling: false
            }).then(async (result) => {
              if (result.value) {
                this.fetchData()
              } else {
                if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                this.timeoutDebounce = setTimeout(
                  () => this.$router.go(-1),
                  300
                )
              }
            })
          })
          .catch((error) => {
            console.log('error upload file ijin bunker', error)
          })
      } catch (error) {
        console.log('upload spog error::', error)
      }
    },
    async uploadSPOG({
      model = null,
      model_id = null,
      singleAppendFile = null,
      uri = 'file_storage',
      no_ijin_bunker = null,
      total_bunker = null,
      exp_ijin_bunker = null
    }) {
      const formData = new FormData()
      let uriAction = `${uri}/${model_id}/${model}`
      if (no_ijin_bunker) {
        uriAction = `${uri}/${model_id}/${model}/${no_ijin_bunker}/${total_bunker}/${exp_ijin_bunker}`
      }

      console.log('uriAction :: ', uriAction)
      console.log('upload :: ', singleAppendFile)

      formData.append('file', singleAppendFile)
      useJwt.http
        .put(uriAction, formData)
        .then((response) => {
          const { file_storages } = response.data
          console.log('file_storages:: ', file_storages)
          this.$alert({
            title: response.data.message,
            variant: 'success',
            icon: 'CheckIcon'
          })
        })
        .catch((err) => {
          console.log(`error ${err}`)
        })
    },
    async sentNotificationDocLengkap() {
      // this method was change into Document SPOG Sudah diterbitkan
      if (this.form.schedule.no_spog_tb) {
        if (this.tempCheckSPOG_tb !== this.form.schedule.no_spog_tb) {
          console.log('sent')
          await this.sentNotification({
            action: this.$notificationMsg['document_lengkap'],
            timeIfPindah: moment(),
            positionIfPindah: `${this.form.schedule.no_spog_tb}`,
            screen_re_fetch: ['form_chain_tbbm']
          })
        } else {
          console.log("don't sent")
        }
      } else {
        console.log('empty')
      }
    },
    async saveRealisasiKegiatan(tabName) {
      if(this.isBerangkatOrBatal() == true) {
        return;
      }
      this.loadingRealisasiKegiatan = true
      console.log('tabname::', tabName)
      if (tabName === 'SPOG' && this.form.schedule.no_spog_file_tb) {
        try {
          await this.uploadSPOG({
            model: 'form_tug_boat',
            model_id: this.form.form_tug_boat_id,
            singleAppendFile: this.form.schedule.no_spog_file_tb
          })
          // todo notification spog
          await this.sentNotificationDocLengkap()
        } catch (error) {
          console.log('upload spog error::', error)
        }
      }
      return new Promise((resolve, reject) => {
        this.$refs.refSAL.validate().then(async (success) => {
          if (success) {
            resolve(true)
            const URI = `form_tug_boat/${this.form.form_tug_boat_id}/tab/sal`
            let payload = {
              waktu_mulai_berkegiatan_tb:
                this.form.schedule.kegiatan_start_time_tb,
              waktu_selesai_berkegiatan_tb:
                this.form.schedule.kegiatan_end_time_tb,
              no_rpkro_tb: this.form.schedule.no_rpkro_tb,
              no_ppk_tb: this.form.schedule.no_ppk_tb,
              no_pindah_tb: this.form.schedule.no_pindah_tb,
              status_kapal_tb: this.form.schedule.status_kapal_tb,
              tab_name: null,
              set_time: true
            }
            if (this.tempCheckRPKRO_tb === this.form.schedule.no_rpkro_tb) {
              payload = {
                ...payload,
                set_time: false
              }
            }
            if (tabName === 'SPOG') {
              payload = {
                tug_boat_id: this.form.schedule.tug_boat_id,
                no_spog_tb: this.form.schedule.no_spog_tb,
                no_spog_tb_brk: this.form.schedule.no_spog_tb_brk,
                tab_name: 'SPOG'
              }
            }
            await useJwt.http
              .put(`${URI}`, payload)
              .then(async () => {
                if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                this.timeoutDebounce = setTimeout(
                  () => (this.loadingRealisasiKegiatan = false),
                  300
                )
                this.$swal({
                  title: 'Good job!',
                  html: 'Berhasil merealisasikan kapal<br>Tap atau Klik dimana saja untuk kembali',
                  icon: 'success',
                  confirmButtonText: 'Lanjutkan Ubah Data',
                  cancelButtonText: 'Kembali',
                  showCancelButton: true,
                  customClass: {
                    confirmButton: 'btn btn-primary',
                    cancelButton: 'btn btn-outline-warning ml-1'
                  },
                  buttonsStyling: false
                }).then(async (result) => {
                  if (result.value) {
                    this.fetchData()
                  } else {
                    if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                    this.timeoutDebounce = setTimeout(
                      () => this.$router.go(-1),
                      300
                    )
                  }
                })
                // todo notification sandar
                await this.sentNotificationDiijinkanSandar()
              })
              .catch((error) => {
                if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                this.timeoutDebounce = setTimeout(
                  () => (this.loadingRealisasiKegiatan = false),
                  300
                )
                this.fetchLoading = false
                this.errorCatch = error
              })
            if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
            this.timeoutDebounce = setTimeout(
              () => (this.loadingRealisasiKegiatan = false),
              300
            )
          } else {
            if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
            this.timeoutDebounce = setTimeout(
              () => (this.loadingRealisasiKegiatan = false),
              300
            )

            reject()
          }
        })
      })
    },
    durationAsString(start, end) {
      const duration = moment.duration(moment(end).diff(moment(start)))

      //Get Days
      const days = Math.floor(duration.asDays()) // .asDays returns float but we are interested in full days only
      const daysFormatted = days ? `${days} days ` : '' // if no full days then do not display it at all

      //Get Hours
      const hours = duration.hours()
      const hoursFormatted = `${hours} hours `

      //Get Minutes
      const minutes = duration.minutes()
      const minutesFormatted = `${minutes} minutes`

      //Get seconds
      var seconds = duration.seconds()
      const secondsFormatted = ` ${seconds} seconds`

      //Get milliseconds
      var milliseconds = moment(end).diff(moment(start))
      const millisecondsFormatted = ` | ${milliseconds} milliseconds`

      return [
        daysFormatted,
        hoursFormatted,
        minutesFormatted,
        secondsFormatted,
        millisecondsFormatted
      ].join('')
    },
    msToTime(duration) {
      var x = duration
      var tempTime = moment.duration(x)
      return `${tempTime.days()} day ${tempTime.hours()} hours ${tempTime.minutes()} minutes ${tempTime.seconds()} seconds | ${x} milliseconds`
    },
    async uploadFileTBBM() {
      const formData = new FormData()
      const model_id = this.form.form_chain_tbbm_id
      const model = 'form_chain_tbbm'
      const uriAction = `file_storage/${model_id}/${model}`

      console.log('upload :: ', this.files)

      this.files.forEach((file) => {
        if (file instanceof File) {
          formData.append('file', file)
        } else {
          console.log(`form data else : ${file}`)
        }
      })
      useJwt.http
        .put(uriAction, formData)
        .then((response) => {
          this.$alert({
            title: response.data.message,
            variant: 'success',
            icon: 'CheckIcon'
          })
        })
        .catch((err) => {
          console.log(`error ${err}`)
          this.errorCatch = err
        })
    },
    dropZoneCallBack(val) {
      console.log('dropZoneCallBack::', val)
      if (val === 'success') {
        // this.fetchData()
        this.filesCheck = null
      } else {
        /*  */
        this.filesCheck = 'have'
        this.files = val
        console.log('this.files::', this.files)
      }
    },
    async onUpdateStatus(isCancel, statusChanged) {
      if (this.$store.state.app.roleName === 'viewer') {
        this.$swal({
          position: 'top-end',
          icon: 'warning',
          title: 'You dont have permission',
          showConfirmButton: false,
          timer: 1500,
          customClass: {
            confirmButton: 'btn btn-primary'
          },
          buttonsStyling: false
        })
        return
      }
      const dataID = router.currentRoute.params.id
      const { schedule } = this.form
      const payload = {
        ftb_only: true,
        is_cancel: isCancel,
        form_status: statusChanged,
        kegiatan_batal_time: moment(),
        set_kegiatan_batal_time: true,
        form_tug_boat_id: this.form.form_tug_boat_id,
        tug_boat_id: this.form.tug_boat.id
      }

      await useJwt.http
        .put(`${schedule.uriStatus}${dataID}`, payload)
        .then(async () => {
          this.$swal({
            // position: 'top-end',
            title: 'Good job!',
            html: 'Berhasil membatalkan kegiatan',
            icon: 'success',
            showConfirmButton: false,
            timer: 3500,
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          })

          if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
          this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
          await this.sentNotification(
            this.$notificationMsg['batal'],
            moment(),
            `Status Sebelumnya ${statusChanged}`
          )
        })
        .catch((error) => {
          this.$swal({
            title: 'Error!',
            text: `${error.response.data.message ?? 'something wrong.'}`,
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary'
            },
            buttonsStyling: false
          }).then(async () => {})
        })
    },
    formatDateTime(value, format = 'D MMMM YYYY - hh:mm A') {
      if (!value) {
        return ''
      }
      const data = moment(value).format(format)
      return data
    },

    onStartChange(selectedDates, dateStr, instance, model) {
      console.log('selectedDates', selectedDates)
      console.log('dateStr', dateStr)
      console.log('instance', instance)
      console.log('model', model)
      this.configs.end.minDate = dateStr
    },
    onEndChange(selectedDates, dateStr, instance) {
      this.dpconfig.maxDate = dateStr
    },
    async sentNotification({
      action,
      timeIfPindah = null,
      positionIfPindah = null,
      screen_re_fetch = null
    }) {
      try {
        await this.setPayloadNotification(
          action,
          timeIfPindah,
          positionIfPindah,
          screen_re_fetch
        )
        useJwt.http
          .post('notification_river', this.payloadNotification)
          .then(() => {
            console.log('success sent [set payload firebase]')
          })
          .catch((error) => {
            console.log('an error sent firebase notification ', error)
          })
      } catch (error) {
        console.log('an error on sent notification :: ', error)
      }
    },
    async setPayloadNotification(
      action,
      timeIfPindah = null,
      positionIfPindah = null,
      screen_re_fetch = null
    ) {
      const path = '#'
      // const path = '/#/dashboard/monitoring/rrv/tbbm'
      const route = process.env.VUE_APP_URL + path

      const tugBoat = this.form.tug_boat
      const form = this.form.schedule

      const posisi = ` Jetty Ditentukan SAL `
      let pkkFinal
      let boatNameFinal
      pkkFinal = `${tugBoat.no_pkk_tug_boat}`
      boatNameFinal = `${tugBoat.name_tug_boat}`
      let form_type = 'FORM_TUG_BOAT'

      this.payloadNotification = {
        /* other db | outside */
        // registrationToken: this.$store.state.app.tokenNotification, // no need to subscribe
        url: route,
        /* self db */
        form_type,
        type: 'light-info', // override only on hulu migas
        action,
        time: timeIfPindah || form.rencana_sandar,
        no_pkk: pkkFinal,
        posisi: positionIfPindah || posisi,

        // router.currentRoute.params.kegiatanDetail || 'Tambat',
        boat_name: boatNameFinal,
        is_read: false,
        form_id: this.form.form_tug_boat_id,
        company: '',
        pemilik: this.form.schedule.pemilik,
        tag: 'river_route',
        screen_re_fetch: screen_re_fetch
      }
    },
    dateFormat(data) {
      return moment(data).format('D MMMM YYYY - hh:mm A')
    },
    getSuggestionValueFocus(
      event,
      model,
      currentSuggest,
      childSuggesst = null,
      HaveChildren = null
    ) {
      console.log(
        `model: ${model}, currentSuggest: ${currentSuggest}, childSuggesst: ${childSuggesst}`
      )
      this.form.currentModel = model
      this.form.currentFieldSuggest = currentSuggest
      this.form.currentFieldSuggestChild = childSuggesst
      this.form.currentFieldHaveChildren = HaveChildren
    },
    getSuggestionValueTKTB(suggestion) {
      console.log('getSuggestionValueTK.item:: ', suggestion.item.asal)
      this.form.schedule.tujuan_kapal_tb = suggestion.item.asal
      this.form.schedule.tujuan_kapal_tb_is_new = false
      return suggestion.item.asal
    },
    getSuggestionValue(suggestion) {
      if (!!suggestion.item.sedang_digunakan) {
        this.$alert({
          title: `Data ${suggestion.item.model_status}`,
          text: `Oleh ${suggestion.item.sedang_digunakan}`,
          variant: 'danger',
          icon: 'XCircleIcon'
        })
        return
      }
      if (
        suggestion.item.tug_status === 'Dalam Perencanaan' &&
        !this.isExistDetailID
      ) {
        this.$alert({
          title: `Data ${suggestion.item.tug_status}, Hanya dapat memilih data ini ketika mengganti kapal tug boat pada form yang telah diterbitkan.`,
          text: ``,
          variant: 'danger',
          icon: 'XCircleIcon'
        })
        return
      }
      const model = this.form.currentModel
      const field = this.form.currentFieldSuggest
      const fieldChild = this.form.currentFieldSuggestChild
      // const haveChildren = this.form.currentFieldHaveChildren

      console.log('clicked', model)
      let result = ''

      if (fieldChild === '' || fieldChild === null || fieldChild === 'null') {
        // !is three data child
        const currentLoadData = this.form[`${model}`][`${model}s`]
        if ('dm_shipping_agent' in suggestion.item) {
          console.log('aw:', suggestion.item)
          const item = {
            ...suggestion.item,
            dm_shipping_agent: {
              isEverRun: false, // default // flag checkpoint if get an error on middle process then continue request
              isNew: false, // POST or PUT
              uri: this.form.tug_boat.dm_shipping_agent.uri, // URI endPoint
              ...suggestion.item.dm_shipping_agent,
              dm_shipping_agents: []
            }
          }
          this.form[`${model}`] = {
            isEverRun: false, // default // flag checkpoint if get an error on middle process then continue request
            isNew: false, // POST or PUT
            uri: this.form[`${model}`].uri, // URI endPoint
            [`${model}s`]: currentLoadData, // use new data or from already exist data on Database
            ...item // replace item from Database
          }
        } else {
          this.form[`${model}`] = {
            isEverRun: false, // default // flag checkpoint if get an error on middle process then continue request
            isNew: false, // POST or PUT
            uri: this.form[`${model}`].uri, // URI endPoint
            [`${model}s`]: currentLoadData, // use new data or from already exist data on Database
            ...suggestion.item // replace item from Database
          }
        }

        // state message after choose
        this[`${model}State`] = 1
        result = suggestion.item[`${field}`]
      } else {
        const split = fieldChild.split('.')
        const currentLoadData =
          this.form[`${model}`][`${split[0]}`][`${split[0]}s`]

        this.form[`${model}`][`${split[0]}`] = {
          isEverRun: false, // default
          isNew: false,
          uri: this.form[`${model}`][`${split[0]}`].uri,
          [`${split[0]}s`]: currentLoadData,
          ...suggestion.item
        }
        this.form[`${model}`] = {
          ...this.form[`${model}`],
          [`${split[0]}_id`]: suggestion.item.id
        }
        this[`${split[0]}State`] = 1
        result = suggestion.item[`${split[1]}`]
      }
      if (result === this.errorMatchValue) {
        this.errorMatchValue = null
      }
      return result
    },
    searchForm({ keyword, uri, model, currentSuggest, childSuggesst = null }) {
      if (keyword) {
        clearTimeout(this.timeoutDebounce)
        this.timeoutDebounce = setTimeout(() => {
          let splitSuggest
          if (childSuggesst !== null) {
            splitSuggest = childSuggesst.split('.')
          }
          // loading|state
          if (childSuggesst === null) {
            // state message
            if (this[`${model}State`] === 1) {
              this[`${model}State`] = 2
            }
            this[`${model}StateLoading`] = true
          } else {
            // state message
            if (this[`${splitSuggest[0]}State`] === 1) {
              this[`${splitSuggest[0]}State`] = 2
            }
            this[`${splitSuggest[0]}StateLoading`] = true
          }
          useJwt.http
            .get(`${uri}`, { params: { keyword, single_search: true } })
            .then((response) => {
              if (response.data.total_items === 0) {
                if (childSuggesst === null) {
                  // no data
                  // this.form.isAgenAndTrans = false
                  this.form[`${model}`].isNew = true
                  this.form[`${model}`][`${model}s`] = []

                  // auto filled
                  this.form[`${model}`].user.full_name = null
                  this.form[`${model}`].created_at = moment()
                  this.form[`${model}`].user.phone = null
                  this[`${currentSuggest}StateLoading`] = false
                } else {
                  // this.form.isAgenAndTrans = false
                  this.form[`${model}`][`${splitSuggest[0]}`].isEverRun = false // new sytnx
                  this.form[`${model}`][`${splitSuggest[0]}`].isNew = true
                  this.form[`${model}`][`${splitSuggest[0]}`][
                    `${splitSuggest[0]}s`
                  ] = ''
                  this[`${splitSuggest[0]}StateLoading`] = false
                }
              } else if (childSuggesst === null) {
                this.form[`${model}`].isNew = true
                // this.form.isAgenAndTrans = false
                this.form[`${model}`][`${model}s`] = response.data[`${model}s`]
                this[`${currentSuggest}StateLoading`] = false
                // this[`${splitSuggest[0]}StateLoading`] = false
              } else {
                // this.form.isAgenAndTrans = false
                this.form[`${model}`][`${splitSuggest[0]}`].isEverRun = false
                this.form[`${model}`][`${splitSuggest[0]}`].isNew = true
                this.form[`${model}`][`${splitSuggest[0]}`][
                  `${splitSuggest[0]}s`
                ] = response.data[`${splitSuggest[0]}s`]
                // loading|state
                this[`${model}StateLoading`] = false
                // this[`${currentSuggest}StateLoading`] = false
                this[`${splitSuggest[0]}StateLoading`] = false
              }
              // loading|state
              this[`${model}StateLoading`] = false
              this[`${currentSuggest}StateLoading`] = false
              // this[`${splitSuggest[0]}StateLoading`] = false
            })
            .catch((e) => {
              // this.form.isAgenAndTrans = false
              this.form[`${model}`].isNew = true
              // this.form[`${model}`].user.full_name = null
              this.form[`${model}`][`${model}s`] = []
              // loading|state
              this[`${model}StateLoading`] = false
              if (childSuggesst !== null) {
                this[`${splitSuggest[0]}StateLoading`] = false
              }
            })
        }, 300)
      }
    },
    repeateAgain() {
      this.items.push({
        id: (this.nextTodoId += this.nextTodoId)
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      // this.trSetHeight(null)
      // this.$nextTick(() => {
      //   this.trSetHeight(this.$refs.form.scrollHeight)
      // })
    },
    // end
    onFocusPemilik() {
      this.fetchPemilik()
    },
    async fetchPemilik() {
      await useJwt.http.get('tbbm_pemilik').then((response) => {
        const data = response.data.tbbm_pemiliks.map((list) => ({
          ...list,
          text: list.name_pemilik,
          value: list.name_pemilik
        }))
        this.pemilik = data
        this.pemilik.unshift({ value: 'null', text: 'Pilih Pemilik' })
      })
    },
    onFocusKegiatan() {
      if (this.kegiatan.length === 0) {
        this.fetchKegiatan()
      }
    },
    async fetchKegiatan() {
      this.fetchLoading = true
      await useJwt.http.get('kegiatan_tbbm').then((response) => {
        const data = response.data.kegiatan_tbbms.map((list) => ({
          ...list,
          text: list.keg_name,
          value: list.keg_name
        }))
        this.kegiatan = data
        this.kegiatan.unshift({ value: 'null', text: 'Pilih Kegiatan' })
        this.fetchLoading = false
      })
    },
    onFocusJetty() {
      if (this.jetty_positions.length === 0) {
        this.fetchJetty()
      }
    },
    async fetchJetty() {
      this.fetchLoading = true
      await useJwt.http.get('jetty_position').then((response) => {
        const data = response.data.jetty_positions.map((list) => ({
          ...list,
          text: list.position,
          value: list.position
        }))
        this.jetty_positions = data.filter((data) => data.position === "JETTY 3A")
        this.jetty_positions.unshift({
          value: 'null',
          text: 'Pilih Posisi Jetty'
        })
        this.fetchLoading = false
      })
    },
    async fetchData() {
      const uriAPI = router.currentRoute.params.api
      const dataID = this.dataID

      if (dataID !== undefined) {
        this.fetchLoading = true

        await useJwt.http
          .get(`${uriAPI}/${dataID}`)
          .then((response) => {
            this.fetchLoading = false
            this.fetchLoading = false
            console.log('fetchData:', response.data[`${uriAPI}s`])

            const form_tug_boat = response.data[`${uriAPI}`]
            const { user, dm_asal_kapal } = form_tug_boat

            this.user_id = user.id
            this.form.created_by = `${user?.full_name} ( ${
              user?.perusahaan || '-'
            } ${user?.phone} )`
            this.form.schedule.jenis_kapal = 'Tug Boat'

            if (!!dm_asal_kapal) {
              this.form.dm_asal_kapal = {
                ...dm_asal_kapal,
                isNew: false,
                dm_asal_kapals: [],
                isEverRun: true,
                uri: 'dm_asal_kapal/',
                updateID: null
              }
              // this.form.schedule.asal = dm_asal_kapal.asal
            }

            if (!!form_tug_boat) {
              const tug_boat = form_tug_boat.tug_boat
              const { dm_shipping_agent } = form_tug_boat.tug_boat
              this.form.form_tug_boat_id = form_tug_boat.id
              this.form.schedule.no_pkk_tug_boat = form_tug_boat.no_pkk_tug_boat

              // this.form.schedule.no_spog_tb = form_tug_boat.no_spog_tb
              // this.form.schedule.no_spog_tb = form_tug_boat.file_storages
              this.no_spog_file_tb = form_tug_boat.file_storages
              this.no_ijin_bunker_file = []

              form_tug_boat.file_storage_bunkers.forEach((item) => {
                this.id_bunker_storage = item.id
                this.form.schedule.no_ijin_bunker = item.no_ijin_bunker
                this.form.schedule.total_bunker = item.total_bunker
                this.form.schedule.exp_ijin_bunker = item.exp_ijin_bunker

                this.form.schedule.kegiatan_start_time_bunker =
                    item.kegiatan_start_time
                  this.form.schedule.kegiatan_end_time_bunker =
                    item.kegiatan_end_time
                this.no_ijin_bunker_file.push({
                  original_name: `${item.original_name}`,
                  size: `${item.size}`,
                  download_link: `${this.BACKEND_URL}${item.path}/${item.unique_name}`
                })
              })
              let kegiatan = form_tug_boat.kegiatan
              this.form.schedule.tujuan_kapal_tb = form_tug_boat.tujuan_kapal_tb

              if (kegiatan.includes('BUNKER')) {
                this.form.schedule.kegiatan =
                  form_tug_boat.kegiatan.split(', ')[0]
                this.form.schedule.kegiatan_tambahan = form_tug_boat.kegiatan
                  .split(', ')
                  .pop()
              } else {
                this.form.schedule.kegiatan = form_tug_boat.kegiatan
              }

              this.form.schedule.position_jetty = form_tug_boat.position_jetty
              this.form.schedule.position_baris_jetty =
                form_tug_boat.baris_position_jetty

              let nowRKA2 = moment(form_tug_boat.tug_sandar_start_time)
              let endRKA2 = moment(form_tug_boat.tug_sandar_end_time) // another date
              this.form_realisasi.rka2 = this.durationAsString(nowRKA2, endRKA2)

              this.form.tug_boat.no_pindah_tb = form_tug_boat.no_pindah_tb
              this.form.tug_boat.rencana_position_jetty =
                form_tug_boat.rencana_position_jetty
              this.form.tug_boat.rencana_baris_position_jetty =
                form_tug_boat.rencana_baris_position_jetty
              this.form.tug_boat.rencana_sandar = form_tug_boat.rencana_sandar
              // Waktu Mulai Berkegiatan Bunker
              this.form.schedule.kegiatan_start_time_tb =
                form_tug_boat.kegiatan_start_time_tb
              this.form.schedule.kegiatan_end_time_tb =
                form_tug_boat.kegiatan_end_time_tb

              this.form.schedule.status_kapal_tb = form_tug_boat.status_kapal_tb
              this.form.schedule.no_pindah_tb = form_tug_boat.no_pindah_tb
              this.form.schedule.no_rkbm_tb = form_tug_boat.no_rkbm_tb
              this.form.schedule.no_pbmbb_tb = form_tug_boat.no_pbmbb_tb
              this.form.schedule.no_rpkro_tb = form_tug_boat.no_rpkro_tb
              this.form.schedule.no_ppk_tb = form_tug_boat.no_ppk_tb
              this.form.schedule.no_spog_tb = form_tug_boat.no_spog_tb

              this.tempCheckSPOG_tb = form_tug_boat.no_spog_tb
              this.tempCheckRPKRO_tb = form_tug_boat.no_rpkro_tb

              this.form.schedule.created_at = form_tug_boat.created_at
              this.form.schedule.updated_at = form_tug_boat.updated_at

              this.form.tug_boat = {
                ...this.form.tug_boat,
                ...tug_boat,
                tug_sandar_start_time: form_tug_boat.tug_sandar_start_time,
                tug_sandar_end_time: form_tug_boat.tug_sandar_end_time,
                user: user
              }
              this.onStartChange('', form_tug_boat.tug_sandar_start_time)

              this.form.tug_boat.dm_shipping_agent = {
                // eslint-disable-next-line camelcase
                ...dm_shipping_agent,
                updateID: dm_shipping_agent.id,
                isEverRun: true,
                uri: 'dm_shipping_agent/',
                isNew: false,
                dm_shipping_agents: []
              }
              if (form_tug_boat.is_cancel === true) {
                this.form.schedule.form_status = 'KEGIATAN DIBATALKAN'
              } else {
                this.form.schedule.form_status = 'KAPAL RENCANA SANDAR'
                if (form_tug_boat.tug_sandar_start_time) {
                  this.form.schedule.form_status = 'KAPAL SANDAR'
                }
                if (form_tug_boat.tug_sandar_end_time) {
                  this.form.schedule.form_status = 'KAPAL BERANGKAT'
                }
              }
              console.log('you got it', form_tug_boat.no_spog_tb_brk)
              this.form.schedule.no_spog_tb_brk = form_tug_boat.no_spog_tb_brk
              console.log('you got it [1]', this.form.schedule.no_spog_tb_brk)
            }
            console.log('init payload:', this.form)
          })
          .catch((err) => {
            this.fetchLoading = false
            console.log(err)
            this.$toast({
              component: ToastificationContent,
              props: {
                title: `Error load data ${err}`,
                icon: 'XIcon',
                variant: 'danger'
              }
            })
          })
          .finally(() => {
            this.fetchLoading = false
            this.configWizardFirst()
          })
      }
    },
    async getIDCreated(jwt, model) {
      let resultID
      await Promise.resolve(jwt).then(async (response) => {
        resultID = response.data[`${model}`].id
      })
      return resultID
    },
    // ableToTrans() {
    //   const { name_agen, full_name_agen } = this.form.agen
    //   const keyword = `name_transportir=${name_agen}&full_name_transportir=${full_name_agen}`
    //   useJwt.http.get('transportir', { params: { keyword } })
    //     .then(res => {
    //       if (res.data.transportirs.length !== 0) {
    //         this.form.showIsAgenAndTrans = true
    //         this.form.temp_transportir_id = res.data.transportirs[0].id
    //       }
    //     })
    // },
    async handlingDistributionData(model, strModel, updateID, parent = null) {
      console.log(
        'action for ',
        model,
        ' and -> ',
        strModel,
        'updateID: ',
        updateID
      )
      if (updateID && model.isNew === false) {
        console.log('doing update:', updateID)
        const jwt = await useJwt.http.put(`${model.uri}${updateID}`, model)
        console.log('is new = false: ', strModel)
        this.form.schedule[`${strModel}_id`] = model.id

        if (parent) {
          console.log(
            'have parent set id : ',
            model.id,
            ' for ',
            `${this.form[`${parent}`][`${strModel}_id`]}`
          )
          this.form[`${parent}`][`${strModel}_id`] = model.id
        } else {
          console.log('not have parent')
        }
      } else if (model.isNew === true) {
        console.log('doing create')
        const jwt = await useJwt.http.post(model.uri, model)
        if (jwt) {
          const id = await this.getIDCreated(jwt, strModel)
          this.form.schedule[`${strModel}_id`] = id
          if (parent) {
            console.log(
              'have parent set id : ',
              id,
              ' for ',
              `${this.form[`${parent}`][`${strModel}_id`]}`
            )
            this.form[`${parent}`][`${strModel}_id`] = id
          } else {
            console.log('not have parent')
          }
        }
      } else {
        console.log(`doing use on exist data: ${strModel}_id`, model.id)
        this.form.schedule[`${strModel}_id`] = model.id
        if (parent) {
          console.log(
            'have parent set id : ',
            model.id,
            ' for ',
            `${this.form[`${parent}`][`${strModel}_id`]}`
          )
          this.form[`${parent}`][`${strModel}_id`] = model.id
        } else {
          console.log('not have parent')
        }
      }
    },
    // saveFormTenantTBBM() {
    //   return new Promise((resolve, reject) => {
    //     this.$refs.tbbmRules.validate().then((success) => {
    //       if (success) {
    //         resolve(true)
    //         this.formTenantTBBM()
    //       } else {
    //         reject()
    //       }
    //     })
    //   })
    // },
    saveMainBoatDT() {
      return new Promise((resolve, reject) => {
        this.$refs.tbbmRulesMainBoat.validate().then((success) => {
          if (success) {
            resolve(true)
            this.formTenantTBBM()
          } else {
            reject()
          }
        })
      })
    },
    saveScheduleDT() {
      return new Promise((resolve, reject) => {
        this.$refs.tbbmRulesSchedule.validate().then((success) => {
          if (success) {
            resolve(true)
            this.formTenantTBBM()
          } else {
            reject()
          }
        })
      })
    },
    saveFormAdminTEMPATRA(isApprove) {
      return new Promise((resolve, reject) => {
        if (this.isBerangkatOrBatal() == true) {
          return
        }
        this.$refs.adminTenantRules.validate().then((success) => {
          if (success) {
            resolve(true)
            this.formAdminTEMPATRA(isApprove)
          } else {
            reject()
          }
        })
      })
    },
    initCreateData() {
      if (this.form.schedule.jenis_kapal === 'Tug Boat') {
        console.log('first')
        return new Promise((resolve, reject) => {
          this.$refs.tbbmRulesTugBoat.validate().then(async (success) => {
            if (success) {
              resolve(true)
              this.form.isTugBoatOnly = true
              this.createTugBoat()
              this.inProgressTerbitkanForm = false
            } else {
              console.log('required', success)
              this.$alert({
                title: `Field Mandatory *`,
                text: `Please check field required *`,
                variant: 'danger',
                icon: 'XCircleIcon'
              })
              this.inProgressTerbitkanForm = false
              reject()
            }
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          this.$refs.tbbmRulesMainBoat.validate().then(async (success) => {
            if (success) {
              this.$refs.tbbmRulesTugBoat.validate().then(async (success) => {
                if (success) {
                  if (this.form.schedule.jenis_kapal === 'Barge') {
                    this.$refs.tbbmRulesFile
                      .validate()
                      .then(async (success) => {
                        if (success) {
                          this.validationCreateData()
                        } else {
                          console.log('required', success)
                          this.$alert({
                            title: `Field Mandatory *`,
                            text: `Please check field required *`,
                            variant: 'danger',
                            icon: 'XCircleIcon'
                          })
                          this.inProgressTerbitkanForm = false
                          reject()
                        }
                      })
                  } else {
                    this.validationCreateData()
                  }
                } else {
                  console.log('required', success)
                  this.$alert({
                    title: `Field Mandatory *`,
                    text: `Please check field required *`,
                    variant: 'danger',
                    icon: 'XCircleIcon'
                  })
                  this.inProgressTerbitkanForm = false
                  reject()
                }
              })
            } else {
              console.log('required', success)
              this.$alert({
                title: `Field Mandatory *`,
                text: `Please check field required *`,
                variant: 'danger',
                icon: 'XCircleIcon'
              })
              this.inProgressTerbitkanForm = false
              reject()
            }
          })
        })
      }
    },
    validationCreateData() {
      this.inProgressTerbitkanForm = true
      return new Promise((resolve, reject) => {
        this.$refs.tbbmRulesSchedule.validate().then(async (success) => {
          if (success) {
            resolve(true)
            let flow = this.form.schedule.jenis_kapal
            if (flow === 'Tug Boat') {
              this.form.isTugBoatOnly = true
              this.createTugBoat()
            } else if (flow === 'Barge') {
              this.form.isTugBoatOnly = false
              if (this.form.isSuccessTugBoat === false) {
                await this.createTugBoat()
              }
              await this.createFormTBBM()
            } else {
              this.form.isTugBoatOnly = false
              await this.createFormTBBM()
            }
            this.inProgressTerbitkanForm = false
          } else {
            console.log('required', success)
            this.$alert({
              title: `Field Mandatory *`,
              text: `Please check field required *`,
              variant: 'danger',
              icon: 'XCircleIcon'
            })
            this.inProgressTerbitkanForm = false
            reject()
          }
        })
      })
    },
    async formTenantTBBM() {
      this.loadingSaveTenant = true
      const dataID = this.dataID
      const {
        schedule,
        dm_river_boat,
        // dm_company_bongm,
        dm_asal_kapal,
        dm_shipping_agent
      } = this.form
      // eslint-disable-next-line camelcase
      const payload = {
        ...schedule,
        dm_river_boat,
        dm_asal_kapal,
        // dm_company_bongm,
        dm_shipping_agent
      }

      await useJwt.http
        .put(`${schedule.uri}${dataID}`, payload)
        .then(async () => {
          if (this.tempCheckPindah !== this.form.schedule.no_pindah) {
            /* info no pindah terbit */
            await this.sentNotification(
              this.$notificationMsg['no_pindah'],
              moment(),
              this.form.schedule.no_pindah
            )
          }
          if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
          this.timeoutDebounce = setTimeout(
            () => (this.loadingSaveTenant = false),
            300
          )
          this.$swal({
            title: 'Good job!',
            html: 'Berhasil menyimpan data<br>Tap atau Klik dimana saja untuk kembali',
            icon: 'success',
            confirmButtonText: 'Lanjutkan Ubah Data',
            cancelButtonText: 'Kembali',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              this.fetchData()
            } else {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            }
          })
        })
        .catch((error) => {
          console.log('an error :', error)
          if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
          this.timeoutDebounce = setTimeout(
            () => (this.loadingSaveTenant = false),
            300
          )

          this.errorCatch = error
        })
    },
    async formAdminTEMPATRA(isApprove) {
      this.loadingTemPatra = true
      const xdataID = router.currentRoute.params.id
      const dataID = this.form.form_tbbm_id
      console.log(` xdataID: ${xdataID} _ dataID: ${dataID} `)
      const { schedule } = this.form
      const payload = {
        no_do_bl: isApprove,
        keterangan_batal_approval: this.form.schedule.keterangan_batal_approval
      }

      await useJwt.http
        .put(`${schedule.uriAgent}${dataID}`, payload)
        .then(async () => {
          if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
          this.timeoutDebounce = setTimeout(
            () => (this.loadingTemPatra = false),
            300
          )
          this.form.form_tbbm_id = dataID
          await this.sentNotification({
            action: this.$notificationMsg['setuju_admin'],
            timeIfPindah: moment(),
            positionIfPindah: `Oleh Admin ${this.form.schedule.pemilik || '-'}`,
            screen_re_fetch: ['form_chain_tbbm']
          })
          this.$swal({
            title: 'Good job!',
            html: ' Berhasil memberikan persetujuan.<br>Tap atau Klik dimana saja untuk kembali',
            icon: 'success',
            confirmButtonText: 'Tetap dsini (Melihat Data)',
            cancelButtonText: 'Kembali',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              this.fetchData()
            } else {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            }
          })
        })
        .catch((error) => {
          if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
          this.timeoutDebounce = setTimeout(
            () => (this.loadingTemPatra = false),
            300
          )

          this.errorCatch = error
        })
    },
    async createFormTugBoat({ tug_boat_id, rencana_sandar }) {
      console.log('createFormTugBoat')
      await useJwt.http
        .post(this.form.uri_chain_second, { tug_boat_id, rencana_sandar })
        .then(async (response) => {
          const dataID = response.data.form_tug_boat.id
          console.log('[debug] form_tug_boat data created : ', dataID)
          this.form.form_tug_boat_id = dataID
          if (this.form.isTugBoatOnly === true) {
            const rencana_kedatangan_tug_boat =
              this.form.tug_boat.rencana_sandar

            await this.sentNotification(
              this.$notificationMsg['rencana_sandar'],
              rencana_kedatangan_tug_boat
            )
            this.$swal({
              title: 'Good job!',
              html: 'Berhasil membuat Form Hulu Migas (Tug Boat Only)!<br>Tap atau Klik dimana saja untuk kembali',
              icon: 'success',
              confirmButtonText: 'Lihat Data Tug Boat',
              cancelButtonText: 'Kembali',
              showCancelButton: true,
              customClass: {
                confirmButton: 'btn btn-primary',
                cancelButton: 'btn btn-outline-warning ml-1'
              },
              buttonsStyling: false
            }).then(async (result) => {
              if (result.value) {
                if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                this.timeoutDebounce = setTimeout(
                  () =>
                    this.$router.push({
                      name: 'dashboard-monitoring-rrv-tug-boat'
                    }),
                  300
                )
              } else {
                if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
                this.timeoutDebounce = setTimeout(
                  () => this.$router.go(-1),
                  300
                )
              }
            })
            return
          }
        })
        .catch((e) => {
          this.fetchLoading = false
          this.errorCatch = e
          this.form.isSuccessTugBoat = false
          this.form.schedule.tug_boat_id = null
        })
    },
    async createTugBoat() {
      console.log('createTugBoat')
      const dataID = this.dataID
      const { tug_boat } = this.form
      if (!tug_boat.no_pkk_tug_boat) {
        return
      }

      if (tug_boat.isNew === false) {
        this.form.schedule.tug_boat_id = tug_boat.id
        if (this.form.tug_boat.form_tug_boat_id) {
          /* form tug doesnt exist */
          this.form.form_tug_boat_id = this.form.tug_boat.id
        } else {
          await this.createFormTugBoat({
            tug_boat_id: this.form.schedule.tug_boat_id,
            rencana_sandar: tug_boat.rencana_sandar
          })
        }
        return
      }

      let breakSwalError = false

      if (dataID) {
      } else {
        if (this.form.tug_boat.form_tug_boat_id) {
          /* form tug doesnt exist */
          this.form.form_tug_boat_id = this.form.tug_boat.id
        } else {
          await useJwt.http
            .post(tug_boat.uri, tug_boat)
            .then(async (response) => {
              this.form.isSuccessTugBoat = true
              this.fetchLoading = false
              const tug_boat_id = response.data.tug_boat.id
              this.form.schedule.tug_boat_id = tug_boat_id
              await this.createFormTugBoat({
                tug_boat_id: this.form.schedule.tug_boat_id,
                rencana_sandar: tug_boat.rencana_sandar
              })
            })
            .catch((e) => {
              if (breakSwalError === false) {
                this.fetchLoading = false
                this.errorCatch = e
                this.form.isSuccessTugBoat = false
                this.form.schedule.tug_boat_id = null
              }
            })
        }
      }
    },
    async createFormTBBM() {
      const dataID = router.currentRoute.params.id
      const {
        // dm_company_bongm,
        dm_river_boat,
        dm_shipping_agent,
        schedule,
        dm_asal_kapal
      } = this.form
      // eslint-disable-next-line camelcase
      let breakSwalError = false

      console.log('dm_shipping_agent check:', dm_shipping_agent)
      if (dm_shipping_agent.isEverRun === false) {
        await this.handlingDistributionData(
          dm_shipping_agent,
          'dm_shipping_agent',
          dm_shipping_agent.updateID
        )
          .then(() => {
            dm_shipping_agent.isEverRun = true
          })
          .catch((error) => {
            this.fetchLoading = false
            this.errorCatch = error
            dm_shipping_agent.isEverRun = false
            breakSwalError = true
            console.log('an error :', error)
          })
      }
      if (breakSwalError) {
        return
      }

      console.log('dm_river_boat check:', dm_river_boat)
      if (dm_river_boat.isEverRun === false) {
        await this.handlingDistributionData(
          dm_river_boat,
          'dm_river_boat',
          dm_river_boat.updateID
        )
          .then(() => {
            dm_river_boat.isEverRun = true
          })
          .catch((error) => {
            this.fetchLoading = false
            this.errorCatch = error
            console.log('an error :', error)
            breakSwalError = true
            dm_river_boat.isEverRun = false
          })
      }

      if (breakSwalError) {
        return
      }
      console.log('dm_asal_kapal check:', dm_asal_kapal)
      if (dm_asal_kapal.isEverRun === false) {
        await this.handlingDistributionData(
          dm_asal_kapal,
          'dm_asal_kapal',
          dm_asal_kapal.updateID
        )
          .then(() => {
            dm_asal_kapal.isEverRun = true
          })
          .catch((error) => {
            this.fetchLoading = false
            this.errorCatch = error
            console.log('an error :', error)
            breakSwalError = true
            dm_asal_kapal.isEverRun = false
          })
      }

      if (breakSwalError) {
        return
      }
      // if (dm_company_bongm.isEverRun === false) {
      //   await this.handlingDistributionData(
      //     dm_company_bongm,
      //     'dm_company_bongm',
      //     dm_company_bongm.updateID
      //   )
      //     .then(() => {
      //       dm_company_bongm.isEverRun = true
      //     })
      //     .catch((error) => {
      //       console.log('an error :', error)
      //       this.fetchLoading = false
      //       if (breakSwalError === false) {
      //         this.$swal({
      //           title: 'Error!',
      //           text: `${
      //             error.response.data.message ??
      //             'Data dm_company_bongm Error, Silahkan cek data kembali.'
      //           }`,
      //           icon: 'error',
      //           customClass: {
      //             confirmButton: 'btn btn-primary'
      //           },
      //           buttonsStyling: false
      //         }).then(async () => {
      //           dm_company_bongm.isEverRun = false
      //         })
      //         breakSwalError = true
      //       }
      //     })
      // }

      if (dataID) {
        console.log('payload update:', schedule)
        await useJwt.http
          .put(`${schedule.uri}${dataID}`, schedule)
          .then(async () => {
            this.fetchLoading = false
            this.$swal({
              title: 'Good job!',
              text: ' Berhasil merivisi Form Masuk & Keluar!',
              icon: 'success',
              customClass: {
                confirmButton: 'btn btn-primary'
              },
              buttonsStyling: false
            }).then(async () => {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            })
            // await this.sentNotification('Revisi Data')
          })
          .catch((e) => {
            if (breakSwalError === false) {
              this.fetchLoading = false
              this.$swal({
                title: 'Error!',
                text: ` Mohon maaf proses update error : ${e}`,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary'
                },
                buttonsStyling: false
              })
            }
          })
      } else {
        await useJwt.http
          .post(schedule.uri, schedule)
          .then(async (response) => {
            this.fetchLoading = false
            const dataID = response.data.form_tbbm.id
            console.log('[debug] form_tbbm data created : ', dataID)
            console.log(
              '[debug] form_tug_boat_id data chain : ',
              this.form.form_tug_boat_id
            )
            await this.createFormChain({
              form_tug_boat_id: this.form.form_tug_boat_id,
              form_tbbm_id: dataID
            })
            // this.$swal({
            //   title: 'Good job!',
            //   text: ' Berhasil membuat Form Perencanaan kedatangan Kapal.',
            //   icon: 'success',
            //   customClass: {
            //     confirmButton: 'btn btn-primary'
            //   },
            //   buttonsStyling: false
            // }).then(async () => {
            //   if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
            //   this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            // })
            // await this.sentNotification('Published')
          })
          .catch((e) => {
            if (breakSwalError === false) {
              this.fetchLoading = false
              this.errorCatch = e
            }
          })
      }
    },
    async createFormChain({ form_tug_boat_id = null, form_tbbm_id = null }) {
      console.log('createFormChain')
      await useJwt.http
        .post(this.form.uri_chain_master, {
          form_tug_boat_id,
          form_tbbm_id
        })
        .then(async (response) => {
          this.fetchLoading = false
          const dataID = response.data.form_chain_tbbm.id
          const form_tbbm_id = response.data.form_chain_tbbm.form_tbbm_id
          console.log('[debug] chain data created : ', dataID)
          console.log('[debug] chain fhm data created : ', form_tbbm_id)
          console.log('[debug] chain ftb data created : ', form_tug_boat_id)
          await this.sentNotification(
            this.$notificationMsg['rencana_sandar']
          ) /* TODO!: FIREBASE NOTIFICATION */
          this.form.form_chain_tbbm_id = dataID
          await this.uploadFileTBBM()
          this.$swal({
            title: 'Good job!',
            html: 'Berhasil membuat Form TBBM!<br>Tap atau Klik dimana saja untuk kembali',
            icon: 'success',
            confirmButtonText: 'Lihat data',
            cancelButtonText: 'Kembali',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(
                () =>
                  this.$nextTick(() => {
                    this.$router.push({
                      name: 'dashboard-monitoring-rrv-tbbm-view',
                      params: {
                        id: dataID,
                        api: this.form.uri_chain_master
                      }
                    })
                  }),
                300
              )
            } else {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            }
          })
        })
        .catch((e) => {
          // if (breakSwalError === false) {
          this.fetchLoading = false
          this.errorCatch = e
          // }
        })
    },
    async saveToUpdateSchedule() {
      // const payload = {
      //   form.tug_boat.rencana_sandar
      //   form.tug_boat.rencana_position_jetty
      //   form.tug_boat.rencana_baris_position_jetty
      // }
    },
    async saveToUpdateDMKTB() {
      if(this.isBerangkatOrBatal() == true) {
        return;
      }
      // todo is tbbm or hulu migas
      this.loadingDupRevTB = true
      let payload = {
        map_state_payload_dmktb: this.statePayloadDMKTB,
        tug_boat: this.form.tug_boat,
        old_tug_boat_id: this.form.tug_boat.id,
        id: this.dataID
      }
      const uriDest =
        this.form.tug_boat.user?.abilities[0]?.action != 'TBBM JETTY 3A'
          ? 'form_hulu_migas'
          : 'form_tbbm'

      const uri = `${uriDest}/${this.dataID}/tenant/dmktb`

      if (uriDest === 'form_hulu_migas') {
        payload = {
          ...payload,
          ftb: {
            id: this.dataID,
            no_pkk_tug_boat: this.form.schedule.no_pkk_tug_boat
          }
        }
      }
      await useJwt.http
        .put(`${uri}`, payload)
        .then(async () => {
          this.loadingDupRevTB = false
          this.$swal({
            title: 'Good job!',
            html: `Berhasil ${this.mapMsgPayloadDMK[this.statePayloadDMKTB]}`,
            icon: 'success',
            confirmButtonText: 'Lanjutkan Ubah Data',
            cancelButtonText: 'Kembali',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.fetchData(), 300)
            } else {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            }
            this.updateDMKTB({ isNotEdit: true, payloadDMK: null })
          })
        })
        .catch((error) => {
          console.log('got an error: ', error)
          this.fetchLoading = false
          this.loadingDupRevTB = false
          this.errorCatch = error
        })
    },
    async jwtGantiKapalTB() {
      this.loadingDupRevTB = true
      const payload = {
        tug_boat: this.form.tug_boat,
        id: this.form.form_tug_boat_id,
        form_chain_tbbm_id: this.form.form_chain_tbbm_id
      }
      const uri = `form_tbbm/${this.form.form_tug_boat_id}/tenant/dmktb/switch/boat`
      await useJwt.http
        .put(`${uri}`, payload)
        .then(async () => {
          this.loadingDupRevTB = false
          this.$swal({
            title: 'Good job!',
            html: `Berhasil mengganti kapal`,
            icon: 'success',
            confirmButtonText: 'Lanjutkan Ubah Data',
            cancelButtonText: 'Kembali',
            showCancelButton: true,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-warning ml-1'
            },
            buttonsStyling: false
          }).then(async (result) => {
            if (result.value) {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.fetchData(), 300)
            } else {
              if (this.timeoutDebounce) clearTimeout(this.timeoutDebounce)
              this.timeoutDebounce = setTimeout(() => this.$router.go(-1), 300)
            }
            this.updateDMKTBMaster({
              isNotEdit: true,
              payloadDMK: null
            })
          })
        })
        .catch((error) => {
          console.log('got an error: ', error)
          this.fetchLoading = false
          this.loadingDupRevTB = false
          this.errorCatch = error
        })
    },
    updateDMKTBMaster({ isNotEdit, payloadDMK }) {
      this.statePayloadDMKTBMaster = payloadDMK
      this.stateInputDMKTBMaster = isNotEdit
    },
    updateDMKTB({ isNotEdit, payloadDMK }) {
      if(this.isBerangkatOrBatal() == true) {
        return;
      }
      this.statePayloadDMKTB = payloadDMK
      this.stateInputDMKTB = isNotEdit
      this.stateInputDMKTBMaster = isNotEdit
    }
  }
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="scss" scoped>
#app {
  .app-content {
    .content-wrapper {
      .content-body {
        #form_masuk {
          .row {
            .suggest-loading {
              // all of suggestion value
              z-index: 99;
              right: 2em;
              top: 2.5em;
              position: absolute; // inside form-input
            }
          }
          .mt-1 {
            .sal::v-deep .wizard-card-footer {
              margin-left: 15.6rem !important;
              @media (min-width: 400px) and (max-width: 900px) {
                margin-left: 0px !important;
              }
            }
            .sal::v-deep .wizard-navigation {
              .wizard-nav {
                li {
                  margin-right: 0 !important;
                }
                width: 18.1rem !important;
                @media (min-width: 400px) and (max-width: 900px) {
                  width: 0 !important;
                }
              }
            }
            .sal-hide::v-deep .wizard-card-footer {
              margin-left: 15.6rem !important;
              @media (min-width: 400px) and (max-width: 900px) {
                margin-left: 0px !important;
              }
              .wizard-footer-right {
                display: none;
              }
            }
            .sal-hide::v-deep .wizard-navigation {
              .wizard-nav {
                li {
                  margin-right: 0 !important;
                }
                width: 18.1rem !important;
                @media (min-width: 400px) and (max-width: 900px) {
                  width: 0 !important;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';
@import '@core/scss/vue/libs/vue-select.scss';
</style>
