<template>
  <b-alert variant="primary" show class="mb-0">
    <div class="alert-body">
      <feather-icon icon="XCircleIcon" class="mr-25" />
      Ubah data <strong>terkunci</strong> karena ijin bunker telah <strong>Expired</strong> dan <strong>Belum Sandar</strong>.
    </div>
  </b-alert>
</template>

<script>
export default {
  name: 'ExpirationAlert',
  // props: {
  //   isExpired: {
  //     type: Boolean,
  //     required: true
  //   },
  // },
  // computed: {
  //   showAlert() {
  //     return this.isExpired;
  //   }
  // }
};
</script>

<style scoped>
</style>
